<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="form"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <mat-accordion style="width: 100%" class="example-headers-align">
          <!-- Detalles -->
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Detalles </mat-panel-title>
              <mat-panel-description>
                Detalles del Punto de Medición
                <mat-icon color="accent">info</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Contenido -->
            <div style="margin-top: 1.5em">
              <!-- División  -->
              <mat-form-field>
                <mat-label>División</mat-label>
                <mat-select formControlName="division">
                  <ng-container *ngFor="let division of divisiones">
                    <mat-option [value]="division"> {{ division }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <div fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <!-- Nombre  -->
                  <div fxFlex="32">
                    <mat-form-field>
                      <mat-label>Nombre</mat-label>
                      <input
                        matInput
                        type="search"
                        placeholder="Nombre del Punto de Medición"
                        formControlName="nombre"
                        required
                        (change)="buscarSimilares()"
                      />
                    </mat-form-field>
                  </div>
                  <!-- descripcion  -->
                  <div fxFlex="32">
                    <mat-form-field>
                      <mat-label>Descripción</mat-label>
                      <input
                        matInput
                        type="search"
                        placeholder="Descripción del Punto de Medición"
                        formControlName="descripcion"
                      />
                    </mat-form-field>
                  </div>
                  <!-- codigoSimec  -->
                  <div fxFlex="32">
                    <mat-form-field
                      *ngIf="form?.value.division === 'Correctoras'"
                    >
                      <mat-label>Nombre Archivo SIMEC</mat-label>
                      <input
                        matInput
                        type="search"
                        placeholder="Nombre Archivo SIMEC"
                        formControlName="codigoSimec"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <!-- Cartel nombres similar -->
                @if (puntoSimilar) {
                  <div
                    fxFlex="100"
                    [innerHTML]="puntoSimilar"
                    class="bg-warn"
                    style="
                      text-align: center;
                      border-radius: 7px;
                      padding: 0.5em;
                      font-size: 1.2em;
                    "
                  ></div>
                }
              </div>

              <h2>Dispositivo</h2>

              <!-- Correctora -->
              <div fxLayout="column">
                <div
                  *ngIf="
                    helper.puedeVerCorrectoras() &&
                    form?.value.division === 'Correctoras'
                  "
                  fxLayout="row"
                  fxLayoutAlign="space-between end"
                  fxLayoutGap="10px"
                  style="width: 100%"
                >
                  <!-- Correctora -->
                  <div fxFlex="40" style="padding-bottom: 4.5px">
                    <ng-select
                      [items]="correctoras"
                      placeholder="Correctora"
                      loadingText="Cargando..."
                      typeToSearchText="Buscar..."
                      notFoundText="No se encontraron resultados"
                      formControlName="idCorrectora"
                      bindLabel="nombre"
                      bindValue="_id"
                      (change)="checkCorrectoraAsignada(); cambioCorrectora()"
                      [appendTo]="'body'"
                    >
                    </ng-select>
                  </div>

                  <!-- DESDE -->
                  <div fxFlex="30" *ngIf="form.get('idCorrectora')?.value">
                    <mat-form-field>
                      <mat-label>Fecha de Asignación Correctora</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="fechaAsignacionCorrectora"
                        (click)="picker.open()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <!-- Estado -->
                  <div *ngIf="form.get('idCorrectora')?.value" fxFlex="30">
                    <mat-form-field>
                      <mat-label>Estado Actual</mat-label>
                      <mat-select formControlName="estado">
                        <ng-container *ngFor="let estado of estados">
                          <mat-option [value]="estado">
                            {{ estado }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Cartel ya asignado -->
                <div
                  *ngIf="asignada"
                  fxFlex="100"
                  [innerHTML]="textoAsignado"
                  class="bg-warn"
                  style="
                    text-align: center;
                    border-radius: 7px;
                    padding: 0.5em;
                    font-size: 1.2em;
                  "
                ></div>
              </div>

              <!-- Presion -->
              <div fxLayout="column">
                <div
                  *ngIf="
                    helper.puedeVerUnidadPresion() &&
                    form?.value.division === 'Presión'
                  "
                  fxLayout="row"
                  fxLayoutAlign="space-between end"
                  fxLayoutGap="10px"
                  style="width: 100%"
                >
                  <!-- Unidad de presion -->
                  <div fxFlex="40">
                    <ng-select
                      [items]="presions"
                      placeholder="Unidad de Presión"
                      loadingText="Cargando..."
                      typeToSearchText="Buscar..."
                      notFoundText="No se encontraron resultados"
                      formControlName="idUnidadPresion"
                      bindLabel="nombre"
                      bindValue="_id"
                      (change)="checkPresionAsignada(); cambioPresion()"
                      [appendTo]="'body'"
                    >
                    </ng-select>
                  </div>

                  <!-- Desde -->
                  <div fxFlex="30" *ngIf="form.get('idUnidadPresion')?.value">
                    <mat-form-field>
                      <mat-label
                        >Fecha de Asignación de Unidad de Presión</mat-label
                      >
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        formControlName="fechaAsignacionUnidadPresion"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <!-- Estado -->
                  <div *ngIf="form.get('idUnidadPresion')?.value" fxFlex="30">
                    <mat-form-field>
                      <mat-label>Estado Actual</mat-label>
                      <mat-select formControlName="estado">
                        <ng-container *ngFor="let estado of estados">
                          <mat-option [value]="estado">
                            {{ estado }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- CARTEL -->
                <div
                  *ngIf="asignada2"
                  fxFlex="100"
                  [innerHTML]="textoAsignado2"
                  class="bg-warn"
                  style="
                    text-align: center;
                    border-radius: 7px;
                    padding: 0.5em;
                    font-size: 1.2em;
                  "
                ></div>
              </div>

              <!-- Scadas -->
              <div fxLayout="column">
                <div
                  *ngIf="
                    helper.puedeVerScada() && form?.value?.division === 'SCADA'
                  "
                  fxLayout="row"
                  fxLayoutAlign="space-between end"
                  fxLayoutGap="10px"
                  style="width: 100%"
                >
                  <!-- SCADAS -->
                  <div fxFlex>
                    <ng-select
                      [items]="scadas"
                      placeholder="SCADAS"
                      loadingText="Cargando..."
                      typeToSearchText="Buscar..."
                      notFoundText="No se encontraron resultados"
                      formControlName="idsScada"
                      bindLabel="nombre"
                      bindValue="_id"
                      (change)="checkScadaAsignada(); cambioScada()"
                      [appendTo]="'body'"
                      [searchable]="true"
                      [multiple]="true"
                    >
                    </ng-select>
                  </div>

                  <!-- Desde -->
                  <div fxFlex="30" *ngIf="form.get('idsScada')?.value">
                    <mat-form-field>
                      <mat-label>Fecha de Asignación de los SCADAS</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker3"
                        formControlName="fechaAsignacionScada"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker3"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <!-- Estado -->
                  <div *ngIf="form.get('idsScada')?.value" fxFlex="30">
                    <mat-form-field>
                      <mat-label>Estado Actual</mat-label>
                      <mat-select formControlName="estado">
                        <ng-container *ngFor="let estado of estados">
                          <mat-option [value]="estado">
                            {{ estado }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- CARTEL -->
                <div
                  *ngIf="asignada4"
                  fxFlex="100"
                  [innerHTML]="textoAsignado4"
                  class="bg-warn"
                  style="
                    text-align: center;
                    border-radius: 7px;
                    padding: 0.5em;
                    font-size: 1.2em;
                  "
                ></div>
              </div>

              <!-- Medidores -->
              <div fxLayout="column">
                <div
                  *ngIf="
                    helper.puedeVerResidencial() &&
                    form?.value.division === 'Residencial'
                  "
                  fxLayout="row"
                  fxLayoutAlign="space-between end"
                  fxLayoutGap="10px"
                  style="width: 100%"
                >
                  <!-- Medidores Residenciales -->
                  <div fxFlex="40">
                    <ng-select
                      [items]="medidores"
                      placeholder="Medidor residencial"
                      loadingText="Cargando..."
                      typeToSearchText="Buscar..."
                      notFoundText="No se encontraron resultados"
                      formControlName="idMedidorResidencial"
                      bindLabel="nombre"
                      bindValue="_id"
                      (change)="checkMedidorAsignado(); cambioMedidor()"
                      [appendTo]="'body'"
                    >
                    </ng-select>
                  </div>

                  <!-- Desde -->
                  <div
                    fxFlex="30"
                    *ngIf="form.get('idMedidorResidencial')?.value"
                  >
                    <mat-form-field>
                      <mat-label>
                        Fecha de Asignación de Unidad de Presión
                      </mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        formControlName="fechaAsignacionMedidorResidencial"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <!-- Estado -->
                  <div
                    *ngIf="form.get('idMedidorResidencial')?.value"
                    fxFlex="30"
                  >
                    <mat-form-field>
                      <mat-label>Estado Actual</mat-label>
                      <mat-select formControlName="estado">
                        <ng-container *ngFor="let estado of estados">
                          <mat-option [value]="estado">
                            {{ estado }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- CARTEL -->
                <div
                  *ngIf="asignada3"
                  fxFlex="100"
                  [innerHTML]="textoAsignado3"
                  class="bg-warn"
                  style="
                    text-align: center;
                    border-radius: 7px;
                    padding: 0.5em;
                    font-size: 1.2em;
                  "
                ></div>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Agrupaciones -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Agrupaciones </mat-panel-title>
              <mat-panel-description>
                Agrupaciones del Punto de Medición
                <mat-icon color="accent">format_list_bulleted</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Contenido -->
            <ng-container>
              <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 1em">
                <!-- Unidad de negocio -->
                <mat-form-field>
                  <mat-label>Unidad de Negocio</mat-label>
                  <mat-select
                    formControlName="idUnidadNegocio"
                    [disabled]="
                      form.value?.idCorrectora || form.value.idUnidadPresion
                    "
                    (selectionChange)="cambioUnidadNegocio()"
                  >
                    <mat-option
                      *ngFor="let dato of unidadNegocios"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </mat-select>
                  <button
                    [disabled]="
                      form.value?.idCorrectora || form.value.idUnidadPresion
                    "
                    *ngIf="form.value?.idUnidadNegocio"
                    matSuffix
                    mat-icon-button
                    type="button"
                    aria-label="Clear"
                    (click)="
                      $event.stopPropagation();
                      form.patchValue({ idUnidadNegocio: null });
                      form.patchValue({ idCentroOperativo: null })
                    "
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <!-- Centro Operativo -->
                <mat-form-field>
                  <mat-label>Centro Operativo</mat-label>
                  <mat-select
                    formControlName="idCentroOperativo"
                    [disabled]="
                      form.value?.idCorrectora || form.value.idUnidadPresion
                    "
                    (selectionChange)="cambioCentroOperativo()"
                  >
                    <ng-container *ngFor="let dato of centrosUnidadNegocio">
                      <mat-option [value]="dato._id">
                        {{ dato.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <button
                    *ngIf="form.value?.idCentroOperativo"
                    [disabled]="
                      form.value?.idCorrectora || form.value.idUnidadPresion
                    "
                    matSuffix
                    mat-icon-button
                    type="button"
                    aria-label="Clear"
                    (click)="
                      $event.stopPropagation();
                      form.patchValue({ idCentroOperativo: null })
                    "
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <!-- Mensaje -->
              <div
                *ngIf="form?.value.idCorrectora || form?.value.idUnidadPresion"
                class="bg-warn"
                style="
                  text-align: center;
                  border-radius: 7px;
                  padding: 0.5em;
                  font-size: 1.2em;
                  margin-bottom: 1em;
                "
              >
                <p>
                  * Unidad de negocio y centro operativo determinados por la
                  {{
                    form.value.idCorrectora
                      ? "unidad correctora"
                      : "unidad de presión"
                  }}
                  seleccionada
                </p>
              </div>

              <br *ngIf="form?.value.idCorrectora" />

              <!-- Cuenca -->
              <mat-form-field *ngIf="form?.value.division === 'Correctoras'">
                <mat-label>Zona de Calidad</mat-label>
                <mat-select formControlName="idCuenca">
                  <mat-option
                    *ngFor="let dato of cuencasUnidadNegocio"
                    [value]="dato._id"
                  >
                    {{ dato.nombre }}
                  </mat-option>
                </mat-select>
                <button
                  *ngIf="form?.get('idCuenca')?.value"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="
                    $event.stopPropagation();
                    form.patchValue({ idCuenca: null })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Grupos -->
              <mat-form-field>
                <mat-label>Grupos</mat-label>
                <mat-select formControlName="idsGrupos" multiple="true">
                  <mat-option
                    *ngFor="let dato of gruposUnidadNegocio"
                    [value]="dato._id"
                  >
                    {{ dato.nombre }}
                  </mat-option>
                </mat-select>
                <button
                  *ngIf="form?.get('idsGrupos')?.value?.length"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="
                    $event.stopPropagation(); form.patchValue({ idsGrupos: [] })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <!-- Agrupacion -->
              <mat-form-field>
                <mat-label>Agrupación</mat-label>
                <mat-select formControlName="idsAgrupaciones" multiple="true">
                  <mat-option
                    *ngFor="let dato of agrupaciones"
                    [value]="dato._id"
                  >
                    {{ dato.nombre }}
                  </mat-option>
                </mat-select>
                <button
                  *ngIf="form?.get('idsAgrupaciones')?.value?.length"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="
                    $event.stopPropagation();
                    form.patchValue({ idsAgrupaciones: [] })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </ng-container>
          </mat-expansion-panel>
          <!-- Ubicación -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Ubicación </mat-panel-title>
              <mat-panel-description>
                Detalles de la Ubicación
                <mat-icon color="accent">map</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
              style="width: 100%"
            >
              <!-- Direccion -->
              <div fxFlex="32">
                <app-autocomplete-direccion
                  [direccion]="this.form.get('direccion')?.value"
                  (onChange)="selectDireccion($event)"
                  style="width: 100%"
                ></app-autocomplete-direccion>
              </div>

              <!-- Centro Operativo -->
              <div fxFlex="32">
                <mat-form-field>
                  <mat-label>Localidad</mat-label>
                  <mat-select formControlName="idLocalidad">
                    <ng-container
                      *ngFor="let dato of localidadsCentroOperativo"
                    >
                      <mat-option [value]="dato._id">
                        {{ dato.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <button
                    *ngIf="form.value?.idLocalidad"
                    matSuffix
                    mat-icon-button
                    type="button"
                    aria-label="Clear"
                    (click)="
                      $event.stopPropagation();
                      form.patchValue({ idLocalidad: null })
                    "
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <!-- Localidad  -->
              <!-- <div fxFlex="32">
                <mat-form-field>
                  <mat-label>Localidad</mat-label>
                  <input
                    matInput
                    type="search"
                    placeholder="Chascomús"
                    formControlName="localidad"
                  />
                </mat-form-field>
              </div> -->

              <!-- Coord Input -->
              <div fxFlex="32">
                <mat-form-field>
                  <mat-label>Coordenadas</mat-label>
                  <input
                    matInput
                    placeholder="lat, lng"
                    [(ngModel)]="coord"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="stringToCoord()"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="stringToCoord()"
                  >
                    <mat-icon color="accent">arrow_forward </mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <!-- Map -->
            <br />
            <div *ngIf="mapCenter">
              <google-map
                width="100%"
                height="400px"
                [options]="mapOptions"
                [center]="mapCenter"
                [zoom]="mapOptions.zoom || 12"
              >
                <map-marker
                  *ngIf="markerOptions.position"
                  [options]="markerOptions"
                  (mapDragend)="setLocation($event)"
                  [position]="markerOptions.position"
                ></map-marker>
              </google-map>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      style="width: 50%"
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="form"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
