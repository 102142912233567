import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IAlerta,
  ICorrectora,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IScada,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { RegistrosService } from '../../modulos/correctoras/registros.service';
import {
  PointOptionsObject,
  SeriesOptionsType,
  SeriesPieOptions,
} from 'highcharts';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-alertas',
  templateUrl: './grafico-alertas.component.html',
  styleUrls: ['./grafico-alertas.component.scss'],
})
export class GraficoAlertasComponent implements OnInit, OnChanges, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;
  @Input() unidadPresion?: IUnidadPresion;
  @Input() scada?: IScada;

  // Reportes Historicos
  public chart?: Highcharts.Options;

  public alertas: IAlerta[] = [];

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public registrosService: RegistrosService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(series: SeriesOptionsType[]) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      title: {
        align: 'left',
        text: undefined,
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      // plotOptions: {
      //   series: {
      //     allowPointSelect: true,
      //     cursor: 'pointer',
      //     dataLabels: [
      //       {
      //         enabled: true,
      //         distance: 20,
      //       },
      //       {
      //         enabled: true,
      //         distance: -40,
      //         format: '{point.percentage:.1f}%',
      //         style: {
      //           fontSize: '1.2em',
      //           textOutline: 'none',
      //           opacity: 0.7,
      //         },
      //         filter: {
      //           operator: '>',
      //           property: 'percentage',
      //           value: 10,
      //         },
      //       } as any,
      //     ],
      //   },
      // },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private async grafico(): Promise<void> {
    if (!this.alertas.length) {
      this.chart = undefined;
      return;
    }

    const alertas: IAlerta[] = JSON.parse(JSON.stringify(this.alertas));

    const series: SeriesOptionsType[] = [];
    const puntos: PointOptionsObject[] = [];

    const tiposAlertas = alertas.map((alerta) => alerta.mensaje);
    const alertasUnicas = [...new Set(tiposAlertas)];

    for (const alertaUnica of alertasUnicas) {
      const alertasFiltradas = alertas.filter(
        (alerta) => alerta.mensaje === alertaUnica,
      );

      const punto: PointOptionsObject = {
        name: alertaUnica,
        y: alertasFiltradas.length,
      };
      puntos.push(punto);
    }

    const serieCorrectedParcializado: SeriesPieOptions = {
      type: 'pie',
      name: `Alertas`,
      data: puntos,
    };

    series.push(serieCorrectedParcializado);

    this.chart = this.getChartOptions(series);
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let filter: IFilter<IAlerta> | boolean = false;
    if (this.correctora) {
      filter = { idCorrectora: this.correctora._id };
    }
    if (this.unidadPresion) {
      filter = { idUnidadPresion: this.unidadPresion._id };
    }
    if (this.puntoMedicion) {
      filter = { idPuntoMedicion: this.puntoMedicion._id };
    }
    if (this.scada) {
      filter = { idScada: this.scada._id };
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
        select: 'mensaje',
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IAlerta>>('alertas', query)
        .subscribe(async (data) => {
          console.log(`listado de alertas`, data);
          this.alertas = data.datos;

          await this.grafico();
        });
      await this.listadosService.getLastValue('alertas', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
