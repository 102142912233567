import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateConfigDispositivo,
  IConfigDispositivo,
  IScada,
  IConfigDispositivoScada,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { ConfigDispositivosService } from '../../modulos/dispositivos/config-dispositivos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-config-scada',
  templateUrl: './config-scada.component.html',
  styleUrls: ['./config-scada.component.scss'],
})
export class ConfigScadaComponent implements OnInit, OnDestroy {
  public form?: FormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public config?: IConfigDispositivoScada;

  // Listado Continuo
  public config$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScada,
    private dialogRef: MatDialogRef<ConfigScadaComponent>,
    private service: ConfigDispositivosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? `Editar Configuración del Scada ${this.data.nombre}`
      : `Crear Configuración del Scada`;

    this.form = new FormGroup({
      limiteHH: new FormControl(this.data.config?.limiteHH, [
        Validators.required,
      ]),
      limiteH: new FormControl(this.data.config?.limiteH, [
        Validators.required,
      ]),
      limiteLL: new FormControl(this.data.config?.limiteLL, [
        Validators.required,
      ]),
      limiteL: new FormControl(this.data.config?.limiteL, [
        Validators.required,
      ]),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getUpsertData() {
    const config: IConfigDispositivoScada = this.form?.value;
    const data: ICreateConfigDispositivo = {
      tag: this.data?.tag,
      config,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data) {
        const data = this.getUpsertData();
        await firstValueFrom(this.service.upsert(data, false, false, false));
        this.helper.notifSuccess('Configuración actualizada');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarConfigDispositivo(): Promise<void> {
    const tag = this.data.tag || '';
    this.config$?.unsubscribe();
    this.config$ = this.listadosService
      .subscribe<IConfigDispositivo>('configDispositivoByTag', tag)
      .subscribe((data) => {
        this.config = data?.config as IConfigDispositivoScada;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listadosService.getLastValue('configDispositivoByTag', tag);
  }

  private updateForm() {
    if (this.config || this.data) {
      this.form?.patchValue({
        limiteHH: this.config?.limiteHH,
        limiteH: this.config?.limiteH,
        limiteLL: this.config?.limiteLL,
        limiteL: this.config?.limiteL,
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarConfigDispositivo()]);
    this.updateForm();
  }

  ngOnDestroy(): void {
    this.config$?.unsubscribe();
  }
}
