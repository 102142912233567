import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IReporteScada, IScada } from 'modelos/src';
import { ConfigScadaComponent } from '../config-scada/config-scada.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-scada-simple',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './scada-simple.component.html',
  styleUrl: './scada-simple.component.scss',
})
export class ScadaSimpleComponent implements OnInit {
  @Input() scada?: IScada;
  public reporte?: IReporteScada;
  constructor(public matDialog: MatDialog) {}

  ngOnInit(): void {
    this.reporte = this.scada?.ultimoRegistro?.valores as IReporteScada;
  }

  public async config(data: IScada) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigScadaComponent, config);
  }
}
