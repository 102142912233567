/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IAlerta,
  IConfigDispositivo,
  IConfigDispositivoScada,
  IReporteScada,
  IScada,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { AlertasService } from '../../alertas/service';
import { ReportesService } from '../../reportes/service';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';
import { CrearEditarScadaComponent } from '../crear-editar-scada/crear-editar-scada.component';
import { ScadasService } from '../service';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss'],
})
export class DetallesComponent implements OnInit, OnDestroy {
  public range?: { from: string; to: string };
  public presets: IRangePreset[] = HelperService.presetUnidadesPresion;

  public range15Dias: IRangePreset = {
    from: this.helper.fechaHaceXDias(15).toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public id?: string | null;
  public scada?: IScada;

  public ultimoReporte?: IReporteScada;
  public ultimaAlerta?: IAlerta;

  public configActual?: IConfigDispositivoScada;
  public configDeseada?: IConfigDispositivoScada;
  public fechaCreacion?: string;

  // Listado Continuo
  public datos$?: Subscription;
  public config$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public reportesService: ReportesService,
    public alertasService: AlertasService,
    public service: ScadasService,
  ) {}

  //

  public async editar(data?: IUnidadPresion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarScadaComponent, config);
  }

  //

  private async listarConfigDispositivo(): Promise<void> {
    this.config$?.unsubscribe();
    const tag = this.scada?.tag;
    if (tag) {
      this.config$ = this.listadosService
        .subscribe<IConfigDispositivo>('configDispositivoByTag', tag)
        .subscribe((data) => {
          this.fechaCreacion = data?.fechaCreacion;
          this.configDeseada = data?.config as IConfigDispositivoScada;
          console.log(`listado de configDispositivo`, data);
          console.log(`configDeseada`, this.configDeseada);
        });
      await this.listadosService.getLastValue('configDispositivoByTag', tag);
    }
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    if (this.id) {
      this.datos$ = this.listadosService
        .subscribe<IScada>('scada', this.id)
        .subscribe(async (data) => {
          this.scada = data;
          this.ultimaAlerta = data?.ultimaAlerta;
          this.ultimoReporte = data?.ultimoRegistro?.valores as IReporteScada;
          this.configActual = data?.config as IConfigDispositivoScada;
          console.log(`listado de scada`, data);
        });
      await this.listadosService.getLastValue('scada', this.id);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listar();
      await Promise.all([this.listarConfigDispositivo()]);
    });
    this.range = {
      from: this.helper.fechaHaceXAnios(1).toISOString(),
      to: this.helper.fechaHaceXDias(-1).toISOString(),
    };
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.config$?.unsubscribe();
  }
}
