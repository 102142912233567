<mat-tab-group>
  @for (s of scadas; track $index) {
    <mat-tab [label]="s.tag!">
      <app-grafico-scada
        [puntoMedicion]="punto"
        [scada]="s"
        [range]="range"
      ></app-grafico-scada>
    </mat-tab>
  }
</mat-tab-group>
