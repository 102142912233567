//ANGUAR Y MATERIAL CORE
import { NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
// V16
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
// V16 Sin usar
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';

// Otros
import { DomSanitizer } from '@angular/platform-browser';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

@NgModule({
  exports: [
    /// V16
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    // MatNativeDateModule,
    // MatRadioModule,
    // MatSlideToggleModule,
    //
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'presion',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/presion.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'claro-oscuro',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/claro-oscuro.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'mapa',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mapa.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'exportar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/exportar.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'correctora',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/correctora.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'mra',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mra.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sml',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/sml.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'punto-medicion',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/punto-medicion.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'unidad-presion',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/unidad-presion.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/stats.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'scada',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/scada.svg'),
    );
  }
}
