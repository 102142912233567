<mat-drawer-container style="height: 100%">
  <mat-drawer
    #drawer
    class="mat-drawer"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxFlex
      style="overflow-x: hidden"
    >
      <!-- Menu -->
      <div fxLayout="row" fxLayoutAlign="center" style="padding: 8px 16px">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="(isHandset$ | async) === false"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>

      <!-- Elementos Menu -->
      <mat-nav-list style="width: 100%; padding-top: 0">
        <mat-divider class="divider-navigator"></mat-divider>

        <a
          mat-list-item
          [routerLink]="'landing'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <span class="text-menu">Inicio</span>
        </a>

        <mat-divider class="divider-navigator"></mat-divider>

        <app-usuario></app-usuario>

        <mat-divider
          class="divider-navigator"
          style="margin-bottom: 0.5em"
        ></mat-divider>
        <!-- Mapa -->
        <a
          *ngIf="helper.puedeVerPuntosDeMedicion()"
          mat-list-item
          [routerLink]="'mapa'"
          [routerLinkActive]="'active'"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon svgIcon="mapa" class="icon-menu" inline></mat-icon>
            </div>
            <span class="text-menu">Mapa</span>
          </div>
        </a>

        <!-- Puntos de Medición -->
        <a
          *ngIf="helper.puedeVerPuntosDeMedicion()"
          mat-list-item
          [routerLink]="'puntosMedicion'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon
                svgIcon="punto-medicion"
                class="icon-menu"
                inline
              ></mat-icon>
            </div>
            <span class="text-menu">Puntos de Medición</span>
          </div>
        </a>

        <!-- Correctoras -->
        <a
          *ngIf="helper.puedeVerCorrectoras()"
          mat-list-item
          [routerLink]="'correctoras'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon
                svgIcon="correctora"
                class="icon-menu"
                inline
              ></mat-icon>
            </div>
            <span class="text-menu">Correctoras de Gas</span>
          </div>
        </a>

        <!-- Unidades de Presión -->
        <!-- *ngIf="helper.puedeVerUnidadPresion()" -->
        <a
          *ngIf="helper.esAdminGlobal() && helper.puedeVerUnidadPresion()"
          mat-list-item
          [routerLink]="'presiones'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon
                svgIcon="unidad-presion"
                class="icon-menu"
                inline
              ></mat-icon>
            </div>
            <span class="text-menu">Unidades de Presión</span>
          </div>
        </a>

        <!-- SCADA -->
        <a
          *ngIf="helper.esAdminGlobal() && helper.puedeVerScada()"
          mat-list-item
          [routerLink]="'scadas'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon svgIcon="scada" class="icon-menu" inline></mat-icon>
            </div>
            <span class="text-menu">SCADA</span>
          </div>
        </a>

        <!-- Medidores Residenciales -->
        <a
          *ngIf="helper.puedeVerResidencial()"
          mat-list-item
          [routerLink]="'medidores'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon svgIcon="sml" class="icon-menu" inline></mat-icon>
            </div>
            <span class="text-menu">Medidores Residenciales</span>
          </div>
        </a>

        <!-- Medidores de agua-->
        <a
          *ngIf="helper.puedeVerMra()"
          mat-list-item
          [routerLink]="'medidoresAgua'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon svgIcon="mra" class="icon-menu" inline></mat-icon>
            </div>
            <span class="text-menu">Medidores de Agua</span>
          </div>
        </a>

        <!-- Exportar -->
        <a
          *ngIf="helper.puedeVerCorrectoras()"
          mat-list-item
          [routerLink]="'exportar'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div style="width: 45px; height: 45px">
              <mat-icon svgIcon="exportar" class="icon-menu" inline></mat-icon>
            </div>
            <span class="text-menu">Exportar</span>
          </div>
        </a>

        <!-- Estadisticas -->
        <a
          *ngIf="helper.puedeVerUnidadPresion()"
          mat-list-item
          [routerLink]="'estadisticas'"
          [routerLinkActive]="'active'"
          style="margin: 0.3em 0"
        >
          <div class="row">
            <div
              style="
                display: flex;
                width: 45px;
                height: 45px;
                align-content: center;
                justify-content: center;
                align-items: center;
              "
            >
              <mat-icon>query_stats</mat-icon>
            </div>
            <span class="text-menu">Estadisticas</span>
          </div>
        </a>
        <mat-divider class="divider-navigator"></mat-divider>
        <!-- Configuracion -->
        <mat-accordion>
          <mat-expansion-panel class="bg-transparente" style="box-shadow: none">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="text-menu" style="font-weight: 800"
                  >Configuración</span
                >
              </mat-panel-title>
              <mat-panel-description>
                <!-- This is a summary of the content -->
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- Usuarios -->
            <a
              *ngIf="helper.puedeVerUsuarios()"
              mat-list-item
              [routerLink]="'usuarios'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Usuarios</span>
            </a>
            <!-- Dispositivos -->
            <a
              *ngIf="helper.puedeVerDispositivos()"
              mat-list-item
              [routerLink]="'dispositivos'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Dispositivos</span>
            </a>
            <!-- Unidad de Negocios -->
            <a
              *ngIf="helper.puedeVerUnidadesNegocio()"
              mat-list-item
              [routerLink]="'unidadnegocios'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Unidad de Negocios</span>
            </a>
            <!-- Centros Operativos -->
            <a
              *ngIf="helper.puedeVerCentrosOperativos()"
              mat-list-item
              [routerLink]="'centroOperativos'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Centros Operativos</span>
            </a>
            <!-- Localidades -->
            <a
              *ngIf="helper.puedeVerLocalidades()"
              mat-list-item
              [routerLink]="'localidades'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Localidades</span>
            </a>
            <!-- Zonas de calidad -->
            <a
              *ngIf="helper.puedeVerCuencas()"
              mat-list-item
              [routerLink]="'cuencas'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Zonas de Calidad</span>
            </a>
            <!-- Grupos -->
            <a
              *ngIf="helper.puedeVerGrupos()"
              mat-list-item
              [routerLink]="'grupos'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Grupos</span>
            </a>
            <!-- Agrupaciones -->
            <a
              *ngIf="helper.puedeVerAgrupaciones()"
              mat-list-item
              [routerLink]="'agrupaciones'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Agrupaciones</span>
            </a>
            <!-- Cromatografias -->
            <a
              *ngIf="helper.puedeVerCromatografias()"
              mat-list-item
              [routerLink]="'cromatografias'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Cromatografías</span>
            </a>
            <!-- Notificaciones -->
            <a
              *ngIf="helper.puedeVerSms()"
              mat-list-item
              [routerLink]="'sms'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Notificaciones</span>
            </a>
            <!-- Auditorias -->
            <a
              *ngIf="helper.puedeVerAuditorias()"
              mat-list-item
              [routerLink]="'auditorias'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Auditorías</span>
            </a>
            <!-- Cambiar contraseña -->
            <a
              mat-list-item
              [routerLink]="'usuario'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Usuario</span>
            </a>
            <!-- APN -->
            <a
              *ngIf="helper.puedeVerApns()"
              mat-list-item
              [routerLink]="'apn'"
              [routerLinkActive]="'active'"
            >
              <span class="text-menu">Apns</span>
            </a>
            <!-- Contenido -->
          </mat-expansion-panel>
        </mat-accordion>

        <mat-divider class="divider-navigator"></mat-divider>

        <a mat-list-item (click)="logout()"
          ><span class="text-menu">Salir</span></a
        >
      </mat-nav-list>

      <!-- Version -->
      <!-- <span class="spacer"></span>
      <div class="versionContainer">
        <h3 style="text-align: center; margin-top: 1em; padding: 0 16px">
          <span> Versión: {{ version }}</span>
          <span *ngIf="env !== 'Prod'"> ({{ env }})</span>
        </h3>
      </div> -->
    </div>
    <!--  -->
  </mat-drawer>
  <mat-sidenav-container>
    <div class="content">
      <div style="margin-left: 1em">
        <mat-toolbar class="hideScroll toolbar">
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="(isHandset$ | async) || !drawer.opened"
          >
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <img [src]="logo" alt="" height="35px" class="logo" />
          <div class="row-center" fxFlex><app-spinner></app-spinner></div>
          @if (!loading.getLoading() && !this.login.tieneTelefono) {
            <div class="row-center" style="width: 100%">
              <span class="warn-telefono" [routerLink]="['/main/usuario']">
                Usuario sin teléfono
              </span>
            </div>
          }
          <button
            *ngIf="helper.esAdminGlobal()"
            mat-icon-button
            (click)="changelog()"
            matTooltip="Versiones de Firmware"
          >
            <mat-icon>developer_board</mat-icon>
          </button>
          <app-notificaciones></app-notificaciones>

          <div style="width: 55px; height: 55px">
            <mat-icon
              class="fg-color"
              svgIcon="claro-oscuro"
              (click)="toggleTheme()"
              style="cursor: pointer"
              inline
            ></mat-icon>
          </div>
        </mat-toolbar>

        <div class="margin-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-container>
</mat-drawer-container>
