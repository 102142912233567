<button
  mat-stroked-button
  (click)="editar(punto); $event.stopPropagation()"
  color="accent"
  style="margin-right: 0.3em"
>
  @if (punto?.posicion && punto?.posicion !== 9999) {
    # {{ punto?.posicion }}
  } @else {
    #
  }
</button>
