import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ICentroOperativo,
  IFilter,
  IGrupo,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IScada,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { NavigationService } from '../../navigation/navigation.service';
import { ScadasService } from '../service';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';
import { CrearEditarScadaComponent } from '../crear-editar-scada/crear-editar-scada.component';
import { ConfigScadaComponent } from 'src/app/standalone/config-scada/config-scada.component';

@Component({
  selector: 'app-scadas',
  templateUrl: './scadas.component.html',
  styleUrls: ['./scadas.component.scss'],
})
export class ScadasComponent implements OnInit, OnDestroy {
  public unidadNegocio?: IUnidadNegocio;

  //// Tabla nueva
  public name = ScadasComponent.name;
  public datos?: IScada[] = [];
  public columnas?: IColumnas<IScada>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['modelo', 'numeroSerie', 'deveui'],
  };
  public saveColumnas = 'cliente-gas-unidad-presion';
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear Unidad de Presión ',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  // Filtros
  public cuenca: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCuenca',
    },
    label: 'Zona de Calidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsGrupos',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public estadoActual: IFiltroTabla = {
    elementos: [
      {
        label: 'Operativa',
        value: [
          { estadoActual: 'Operativa' },
          { estadoActual: { $exists: false } },
        ],
      },
      {
        label: 'En Mantenimiento',
        value: [{ estadoActual: 'En Mantenimiento' }],
      },
      { label: 'Resolver', value: [{ estadoActual: 'Resolver' }] },
    ],
    filter: {
      field: '$or',
    },
    label: 'Estado Actual',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };

  public dispositivo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'deveui',
    },
    label: 'Dispositivo',
    selectLabel: 'deviceName',
    selectValue: 'deveui',
    tipo: 'ngselect',
  };

  public estado: IFiltroTabla = {
    elementos: [
      {
        label: 'Operativa',
      },
      {
        label: 'Sin Reportar',
      },
      {
        label: 'Resolver',
      },
      {
        label: 'En Mantenimiento',
      },
      {
        label: 'Alerta',
      },
      {
        label: 'Sin Asignar',
      },
    ],
    filter: {
      field: 'estadoActual',
    },
    label: 'Estado',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };

  public filtros: IFiltroTabla[] = [
    this.cuenca,
    this.centroOperativo,
    this.grupo,
    this.estadoActual,
    this.dispositivo,
    this.estado,
  ];

  // QUERY+
  private populate = [
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'nombre tag',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public unidadNegocio$?: Subscription;
  public centroOperativos$?: Subscription;
  public grupos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: ScadasService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  // ACCIONES
  public async detalles(d: IScada) {
    await this.router.navigate(['main', 'scadas', `${d._id}`]);
  }

  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarScadaComponent, config);
  }

  public async editar(data: IScada) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarScadaComponent, config);
  }

  public async eliminar(dato: IScada): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la unidad de presión ${dato.nombre} S/N: ${dato.tag}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async config(data: IScada) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigScadaComponent, config);
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<IScada> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }
    this.query.filter = JSON.stringify(filter);

    // Listado
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IScada>>('scadas', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de scadas`, data);
      });
    await this.listadosService.getLastValue('scadas', this.query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Filtro
    const filter: IFilter<ICentroOperativo> = {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativo.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICentroOperativo[];
        this.centroOperativo.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarGrupos(): Promise<void> {
    // Filtro
    const filter: IFilter<IGrupo> = {};
    if (this.unidadNegocio) {
      filter.idUnidadNegocio = this.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as IGrupo[];
        this.grupo.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: [] },
        });
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private getFiltroGlobal() {
    this.unidadNegocio$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.unidadNegocio = filtro.unidadNegocio;
        this.listar();
      });
    const filtro = this.navigationService.getFiltroGlobal();
    this.unidadNegocio = filtro.unidadNegocio;
  }

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Estado',
          sortable: true,
          sort: 'estadoActual',
        },
        row: {
          icono: (dato) => this.helper.estadoIconoTabla(dato.estadoActual),
        },
      },
      {
        header: {
          label: 'Tag',
          sortable: true,
        },
        row: {
          field: 'tag',
          noData: 'Sin información',
        },
      },
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
          noData: 'Sin información',
        },
      },
      {
        header: {
          label: 'Tipo',
          sortable: true,
        },
        row: {
          field: 'tipo',
          noData: 'Sin información',
        },
      },
      {
        header: {
          label: 'Fecha Último Registro',
          sortable: true,
          sort: 'dato.ultimoRegistro?.valores?.timestamp',
        },
        row: {
          parse: (dato) => {
            return dato.ultimoRegistro?.valores?.timestamp;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
          noData: 'Sin reportar',
        },
      },
      {
        header: {
          label: 'Fecha Última Alerta',
          sortable: true,
          sort: 'ultimaAlerta.fechaCreacion',
        },
        row: {
          parse: (dato) => {
            return dato.ultimaAlerta?.fechaCreacion;
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
          noData: 'Sin reportar',
        },
      },
      {
        header: {
          label: 'Unidad de Negocio',
        },
        row: {
          html: (dato) => this.parseUnidadDeNegocio(dato),
        },
      },
      {
        header: {
          label: 'Centro Operativo',
        },
        row: {
          html: (dato) => this.parseCentroOperativo(dato),
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: async (dato) => this.detalles(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/config.png',
              tooltip: 'Configurar',
              click: async (dato) => this.config(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  // private parseDeviceName(d: IScada): string {
  //   if (d.dispositivo?.deviceName) {
  //     return `<div>${d.dispositivo?.deviceName}</div>`;
  //   } else {
  //     return `<div class="no-info">Sin información</div>`;
  //   }
  // }

  // private parseFecha(fecha: string): string {
  //   return `<div>${new Date(fecha).toLocaleDateString()} - ${new Date(
  //     fecha,
  //   ).toLocaleTimeString()}</div>`;
  // }

  private parseUnidadDeNegocio(d: IScada): string {
    if (d.unidadNegocio) {
      return `${d.unidadNegocio.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  private parseCentroOperativo(d: IScada): string {
    if (d.centroOperativo) {
      return `${d.centroOperativo.nombre}`;
    } else {
      return `<div class="no-info">Sin información</div>`;
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.getFiltroGlobal();
    this.setColumnas();
    await Promise.all([
      this.listar(),
      this.listarCentrosOperativos(),
      this.listarGrupos(),
    ]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.unidadNegocio$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.grupos$?.unsubscribe();
  }
}
