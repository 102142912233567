<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      @for (s of scadas; track $index) {
        <span class="tag" matTooltip="{{ s.tag }}" (click)="seleccionar(s)">
          {{ s.tag }}
        </span>
      }
    </div> -->
    <div class="titulo chips-container">
      <mat-chip-listbox>
        @for (s of scadas; track $index) {
          <mat-chip-option
            class="chiquito"
            matTooltip="{{ s.tag }}"
            (click)="seleccionar(s)"
            selected="{{ s.tag === scadaSeleccionado?.tag }}"
          >
            {{ parseTag(s.tag!) }}
          </mat-chip-option>
        }
        <!-- @for (s of tags; track $index) {
          <mat-chip-option class="chiquito" matTooltip="{{ s }}">
            {{ parseTag(s) }}
          </mat-chip-option>
        } -->
      </mat-chip-listbox>
    </div>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Identificación -->
      <tr>
        <th>Identificación</th>
        <td style="text-align: end">
          @if (scadaSeleccionado?.nombre) {
            {{ scadaSeleccionado?.nombre }}
          } @else {
            "..."
          }
        </td>
      </tr>
      <!-- Tag -->
      <tr>
        <th>Tag</th>
        <td style="text-align: end">
          @if (scadaSeleccionado?.tag) {
            {{ scadaSeleccionado?.tag }}
          } @else {
            "..."
          }
        </td>
      </tr>
      <!-- Fecha de Asignación -->
      <tr>
        <th>Fecha de Asignación</th>
        <td style="text-align: end">
          @if (puntoMedicion?.fechaAsignacionScada) {
            {{ puntoMedicion?.fechaAsignacionScada | date: "short" }}
          } @else {
            "..."
          }
        </td>
      </tr>

      @if (scadaSeleccionado?.config) {
        <!-- Config -->
        <ng-container>
          <!-- Separador -->
          <tr>
            <td colspan="100%">
              <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
            </td>
          </tr>

          <!-- ACTUAL -->
          <!-- Limite LL -->
          <tr>
            <th>Límite LOLO</th>
            <td style="text-align: end">
              {{ scadaSeleccionado?.config?.limiteLL | number: "1.0-2" }}
            </td>
          </tr>
          <!-- Limite L -->
          <tr>
            <th>Límite LO</th>
            <td style="text-align: end">
              {{ scadaSeleccionado?.config?.limiteL | number: "1.0-2" }}
            </td>
          </tr>
          <!-- Limite HH -->
          <tr>
            <th>Límite HIHI</th>
            <td style="text-align: end">
              {{ scadaSeleccionado?.config?.limiteHH | number: "1.0-2" }}
            </td>
          </tr>
          <!-- Limite HI -->
          <tr>
            <th>Límite HI</th>
            <td style="text-align: end">
              {{ scadaSeleccionado?.config?.limiteH | number: "1.0-2" }}
            </td>
          </tr>
        </ng-container>
      }
    </table>
  </div>
</div>
