import { Component } from '@angular/core';
import { HelperService } from 'src/app/auxiliares/helper.service';

@Component({
  selector: 'app-landing-puntos',
  standalone: false,
  templateUrl: './landing-puntos.component.html',
  styleUrl: './landing-puntos.component.scss',
})
export class LandingPuntosComponent {
  constructor(public helper: HelperService) {}
}
