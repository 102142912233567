import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IConfigDispositivoScada, IReporteScada, IScada } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-valor-actual-scada',
  templateUrl: './grafico-valor-actual-scada.component.html',
  styleUrls: ['./grafico-valor-actual-scada.component.scss'],
})
export class GraficoValorActualScadaComponent implements OnInit, OnChanges {
  @Input() scada?: IScada;

  public chartValorActual?: Highcharts.Options;

  constructor(public helper: HelperService) {}

  private graficoValorActual() {
    const config = this.scada?.config as IConfigDispositivoScada;
    console.log('config GRAFICO VALOR ACTUAL', config);

    const ultimoReporte = this.scada?.ultimoRegistro?.valores as IReporteScada;
    const limiteLL = config?.limiteLL || 0;
    const limiteHH = config?.limiteHH || 10;
    const valorActual = +(ultimoReporte?.valorActual?.toFixed(2) || 0);

    const limiteL = config?.limiteL || 3;
    const limiteH = config?.limiteH || 7;

    const unidad = this.scada?.tipo === 'Temperatura' ? '°C' : 'm3';

    const options: Highcharts.Options = {
      chart: {
        type: 'gauge',
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
        plotBackgroundColor: `transparent`,
        plotBackgroundImage: `transparent`,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%',
      },

      title: {
        // text: 'Presión actual',
        text: null,
      },

      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%',
      },

      // the value axis
      yAxis: {
        min: 0,
        max: config?.limiteHH ? config?.limiteHH + 10 : 10,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        // tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 20,
          style: {
            fontSize: '14px',
            color: HelperService.esModoOscuro() ? 'white' : 'black',
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: limiteLL,
            color: '#df3131', // red
            thickness: 20,
          },
          {
            from: limiteLL,
            to: limiteL,
            color: '#e9a921', // yellow
            thickness: 20,
          },
          {
            from: limiteL,
            to: limiteH,
            color: '#1bd139', // yellow
            thickness: 20,
          },
          {
            from: limiteH,
            to: limiteHH + 10,
            color: '#eb115a', // red
            thickness: 20,
          },
        ],
      },

      series: [
        {
          name: this.scada?.tipo,
          data: [valorActual],
          tooltip: {
            valueSuffix: ` ${unidad}`,
          },
          dataLabels: {
            format: `{y} ${unidad}`,
            borderWidth: 0,
            // color: (
            //     Highcharts.defaultOptions.title &&
            //     Highcharts.defaultOptions.title.style &&
            //     Highcharts.defaultOptions.title.style.color
            // ) || '#333333',
            style: {
              fontSize: '16px',
            },
          },
          dial: {
            radius: '80%',
            backgroundColor: 'gray',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%',
          },
          pivot: {
            backgroundColor: 'gray',
            radius: 6,
          },
        },
      ],
    } as any;

    this.chartValorActual = options;
  }

  async ngOnChanges(): Promise<void> {
    this.graficoValorActual();
  }

  async ngOnInit(): Promise<void> {
    this.graficoValorActual();
  }
}
