<div class="fondo-85" style="border-radius: 30px">
  <div
    *ngIf="!this.loading.getLoading() && !reportes.length"
    style="margin: 1em; text-align: center; padding: 0.5em"
  >
    <h2 style="margin: 0">No hay reportes en el período seleccionado</h2>
  </div>

  <!-- Graficos -->
  <div *ngIf="reportes.length" style="margin: 1em">
    <mat-tab-group>
      <!-- Presion -->
      <mat-tab [label]="scada?.tipo!">
        <ng-template matTabContent>
          <div *ngIf="chart" class="transparente" style="margin: 1em">
            <app-chart
              [options]="chart"
              style="
                width: 100%;
                height: 400px;
                display: block;
                margin-top: 30px;
              "
            >
            </app-chart>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
