import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { UltimoReporteScadaComponent } from '../ultimo-reporte-scada.component';
import { IScada } from 'modelos/src';

@Component({
  selector: 'app-multi-scada-ultimo-reporte',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, UltimoReporteScadaComponent],
  templateUrl: './multi-scada-ultimo-reporte.component.html',
  styleUrl: './multi-scada-ultimo-reporte.component.scss',
})
export class MultiScadaUltimoReporteComponent {
  @Input() scadas?: IScada[];
}
