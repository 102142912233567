<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Editar Posición
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <mat-form-field class="example-full-width">
      <mat-label>Posición</mat-label>
      <input matInput type="number" [(ngModel)]="index" name="index" required />
    </mat-form-field>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="button"
      form="form"
      color="accent"
      style="width: 50%"
      (click)="guardar()"
      [disabled]="!index"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="form"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
