<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Límite LOLO  -->
      <mat-form-field>
        <mat-label>Límite LOLO</mat-label>
        <input matInput type="number" formControlName="limiteLL" required />
      </mat-form-field>

      <!-- Límite LO  -->
      <mat-form-field>
        <mat-label>Límite LO</mat-label>
        <input matInput type="number" formControlName="limiteL" required />
      </mat-form-field>

      <!-- Límite HIHI  -->
      <mat-form-field>
        <mat-label>Límite HIHI</mat-label>
        <input matInput type="number" formControlName="limiteHH" required />
      </mat-form-field>

      <!-- Límite HI  -->
      <mat-form-field>
        <mat-label>Límite HI</mat-label>
        <input matInput type="number" formControlName="limiteH" required />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
