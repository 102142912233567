import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IQueryParam,
  IAlerta,
  IUnidadPresion,
  ICorrectora,
  IPuntoMedicion,
  IScada,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { NOMBRE_ALERTAS_NUC } from '../../../environments/datos';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import { AlertasService } from '../../modulos/alertas/service';
import {
  IColumnas,
  IExportarBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { CommonModule, DatePipe } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IRegExpSearch } from '../../auxiliares/tabla/filtro/filtro.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-alertas',
  templateUrl: './listado-alertas.component.html',
  styleUrls: ['./listado-alertas.component.scss'],
})
export class ListadoAlertasComponent implements OnInit, OnChanges, OnDestroy {
  public nombreAlertas = NOMBRE_ALERTAS_NUC;

  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() unidadPresion?: IUnidadPresion;
  @Input() correctora?: ICorrectora;
  @Input() scada?: IScada;

  ///// Tabla nueva
  public name = 'ListadoAlertasComponent';
  public datos?: IAlerta[] = [];
  public columnas?: IColumnas<IAlerta>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['mensaje', 'estado'],
  };
  public botonExportar: IExportarBoton = {
    accion: async () => this.exportar(),
    mostrar: true,
    tooltip: 'Exportar Alertas',
  };

  // QUERY+
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-timestamp',
    filter: JSON.stringify({}),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private dialogService: DialogService,
    public service: AlertasService,
  ) {}

  //

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de alertas?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        await this.service.exportar(this.query);
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    let listar = false;
    const filter = JSON.parse(this.query.filter || '{}') as IFilter<IAlerta>;
    if (this.unidadPresion) {
      filter.idUnidadPresion = this.unidadPresion._id;
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }
    if (this.correctora) {
      filter.idCorrectora = this.correctora._id;
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }
    if (this.puntoMedicion) {
      filter.idPuntoMedicion = this.puntoMedicion._id;
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }
    if (this.scada) {
      filter.idScada = this.scada._id;
      this.query.filter = JSON.stringify(filter);
      listar = true;
    }

    if (listar) {
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IAlerta>>('alertas', this.query)
        .subscribe((data) => {
          this.totalCount = data.totalCount;
          this.datos = data.datos;
          console.log(`listado de alertas`, data);
        });
      await this.listadosService.getLastValue('alertas', this.query);
    }
  }

  //

  private setColumnas() {
    this.columnas = [
      {
        header: { label: 'Estado', sortable: true },
        row: {
          field: 'estado',
          claseCss: (row) => {
            if (row.estado === 'Activo') {
              return 'label-error';
            }
            return;
          },
        },
      },
      {
        header: { label: 'Fecha', sortable: true },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: { label: 'Alerta', sortable: true },
        row: {
          field: 'mensaje',
        },
      },
      {
        header: { label: 'Último estado' },
        row: {
          parse: (row) => {
            if (row.fechaCierre) {
              return row.fechaCierre;
            } else {
              return row.timestamp;
            }
          },
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
    ];
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
