@if (scada) {
  <section class="layout" style="margin-bottom: 0.3em">
    <!-- NOMBRE -->
    <div
      class="tag"
      [matTooltip]="scada.tag || ''"
      [matTooltipPosition]="'above'"
    >
      {{ scada.nombre }}
    </div>
    <!-- CV -->
    <div class="valor">{{ reporte?.valorActual | number }}</div>
    <!-- LOLO -->
    <div class="valor">{{ scada.config?.limiteLL || 0 }}</div>
    <!-- LO -->
    <div class="valor">{{ scada.config?.limiteL || 0 }}</div>
    <!-- HI -->
    <div class="valor">{{ scada.config?.limiteH || 0 }}</div>
    <!-- HIHI -->
    <div class="valor">{{ scada.config?.limiteHH || 0 }}</div>
    <!-- ACCIONES -->
    <div class="valor">
      <img
        src="assets/iconos/config.png"
        (click)="config(scada)"
        style="cursor: pointer; height: 25px"
        tooltip="Configurar"
      />
    </div>
  </section>
} @else {
  <!-- EJEMPLO PARA USAR DE ENCABEZADO -->
  <section class="layout">
    <!-- NOMBRE -->
    <div class="tag-ejemplo no-info">SCADA</div>
    <!-- CV -->
    <div class="valor-ejemplo no-info">CV</div>
    <!-- LOLO -->
    <div class="valor-ejemplo no-info">LOLO</div>
    <!-- LO -->
    <div class="valor-ejemplo no-info">LO</div>
    <!-- HI -->
    <div class="valor-ejemplo no-info">HI</div>
    <!-- HIHI -->
    <div class="valor-ejemplo no-info">HIHI</div>
    <!-- ACCIONES -->
    <div class="valor-ejemplo no-info">ACCIONES</div>
  </section>
}
