import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICentroOperativo,
  ICreateUnidadPresion,
  IListado,
  IScada,
  IQueryParam,
  IUnidadNegocio,
  IUpdateUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { GeocodeService } from '../../../auxiliares/geocode.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { ScadasService } from '../service';

@Component({
  selector: 'app-crear-editar-scada',
  templateUrl: './crear-editar-scada.component.html',
  styleUrls: ['./crear-editar-scada.component.scss'],
})
export class CrearEditarScadaComponent implements OnInit, OnDestroy {
  public form?: FormGroup;
  public title?: string;
  public enviando = false;
  public hide = true;

  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public centrosUnidadNegocio: ICentroOperativo[] = [];

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptionsEditor),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScada,
    private dialogRef: MatDialogRef<CrearEditarScadaComponent>,
    private service: ScadasService,
    private listadosService: ListadosService,
    private geocodeService: GeocodeService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Presión SCADA'
      : 'Crear Presión SCADA';

    this.form = new FormGroup({
      nombre: new FormControl(this.data?.nombre, [Validators.required]), /// Input
      tag: new FormControl(this.data?.tag, [Validators.required]), /// Esto configura el tag para el OPC
      estadoActual: new FormControl(this.data?.estadoActual || 'Operativa'),
      tipo: new FormControl(this.data?.tipo, [Validators.required]),
      // Selects
      idUnidadNegocio: new FormControl(this.data?.idUnidadNegocio),
      idCentroOperativo: new FormControl(this.data?.idCentroOperativo),
      idLocalidad: new FormControl(this.data?.idLocalidad),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public setDatos() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    const centrosUnidadNegocio = this.centroOperativos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );

    this.centrosUnidadNegocio = centrosUnidadNegocio;
  }

  public cambioUnidadNegocio() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;

    this.setDatos();

    if (this.centrosUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCentroOperativo: this.centrosUnidadNegocio[0]._id,
      });
    } else {
      const idCentroSeleccionado = this.form?.value.idCentroOperativo;
      const centroSeleccionado = this.centrosUnidadNegocio.find(
        (c) => c._id === idCentroSeleccionado,
      );
      if (centroSeleccionado?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCentroOperativo: null,
        });
      }
    }
  }

  //

  public async selectDireccion(direccion: string) {
    this.form?.patchValue({ direccion });
    const coordenadas = await this.geocodeService.getCoordinates(direccion);
    if (coordenadas) {
      this.markerOptions = {
        position: coordenadas,
        draggable: true,
      };
      this.mapCenter = coordenadas;
      this.mapOptions.zoom = 16;
      this.form?.get('ubicacion')?.patchValue({ lat: coordenadas.lat });
      this.form?.get('ubicacion')?.patchValue({ lng: coordenadas.lng });
    }
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();
    if (coords) {
      this.form?.get('ubicacion')?.patchValue({ lat: coords.lat });
      this.form?.get('ubicacion')?.patchValue({ lng: coords.lng });
    }
  }

  //

  private getUpdateData() {
    const data: IUpdateUnidadPresion = this.form?.value;
    return data;
  }

  private getCreateData() {
    const data: ICreateUnidadPresion = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;

        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
          this.setDatos();
        }

        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
    this.setDatos();
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
  }
}
