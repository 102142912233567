<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="form"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Estado Actual -->
      <mat-form-field>
        <mat-label>Estado Actual</mat-label>
        <mat-select formControlName="estadoActual">
          <mat-option value="Operativa"> Operativa </mat-option>
          <mat-option value="En Mantenimiento"> En Mantenimiento </mat-option>
          <!-- <mat-option value="Resolver"> Resolver </mat-option> -->
        </mat-select>
      </mat-form-field>

      <!-- Tipo -->
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="tipo">
          <mat-option value="Presión"> Presión </mat-option>
          <mat-option value="Temperatura"> Temperatura </mat-option>
          <!-- <mat-option value="Resolver"> Resolver </mat-option> -->
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="15px">
        <!-- Nombre -->
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput type="search" formControlName="nombre" />
        </mat-form-field>

        <!-- Tag OPC  -->
        <mat-form-field>
          <mat-label>Tag OPC</mat-label>
          <input matInput type="search" formControlName="tag" />
        </mat-form-field>
      </div>

      <!-- Unidad de negocio y centro operativo -->
      <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 1em">
        <!-- Unidad de negocio -->
        <mat-form-field>
          <mat-label>Unidad de Negocio</mat-label>
          <mat-select
            formControlName="idUnidadNegocio"
            [disabled]="form.value?.deveui"
            (selectionChange)="cambioUnidadNegocio()"
          >
            <mat-option *ngFor="let dato of unidadNegocios" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </mat-select>
          <button
            [disabled]="form.value?.deveui"
            *ngIf="form.value?.idUnidadNegocio"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="
              $event.stopPropagation();
              form.patchValue({ idUnidadNegocio: null });
              form.patchValue({ idCentroOperativo: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- Centro operativo -->
        <mat-form-field>
          <mat-label>Centro Operativo</mat-label>
          <mat-select formControlName="idCentroOperativo">
            <ng-container *ngFor="let dato of centrosUnidadNegocio">
              <mat-option [value]="dato._id">
                {{ dato.nombre }}
              </mat-option>
            </ng-container>
          </mat-select>
          <button
            *ngIf="form.value?.idCentroOperativo"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="
              $event.stopPropagation();
              form.patchValue({ idCentroOperativo: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
