import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Division,
  IAgrupacion,
  ICentroOperativo,
  ICoordenadas,
  ICorrectora,
  ICreatePuntoMedicion,
  ICuenca,
  IFilter,
  IGrupo,
  IListado,
  ILocalidad,
  IMedidorResidencial,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
  IScada,
  IUnidadNegocio,
  IUnidadPresion,
  IUpdateCorrectora,
  IUpdatePuntoMedicion,
  IUpdateUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { PuntosMedicionService } from '../service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

type CorrectoraSelect = ICorrectora & { nombre?: string };

type UnidadPresionSelect = IUnidadPresion & { nombre?: string };

type ScadaSelect = IScada & { nombre?: string };

@Component({
  selector: 'app-crear-editar-puntos-medicion',
  templateUrl: './crear-editar-puntos-medicion.component.html',
  styleUrls: ['./crear-editar-puntos-medicion.component.scss'],
})
export class CrearEditarPuntosMedicionComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  private ubicacionDispositivo?: ICoordenadas;
  public ubicacion?: ICoordenadas;
  public coord?: string = '';
  public asignada?: boolean = false;
  public asignada2?: boolean = false;
  public asignada3?: boolean = false;
  public asignada4?: boolean = false;
  public textoAsignado?: string = '';
  public textoAsignado2?: string = '';
  public textoAsignado3?: string = '';
  public textoAsignado4?: string = '';

  public puntoSimilar?: string = '';

  public expanded = true;
  public expanded1 = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public localidads: ILocalidad[] = [];
  public grupos: IGrupo[] = [];
  public agrupaciones: IAgrupacion[] = [];
  public correctoras: ICorrectora[] = [];
  public puntosMedicion: IPuntoMedicion[] = [];
  public presions: IUnidadPresion[] = [];
  public medidores: IMedidorResidencial[] = [];
  public scadas: IScada[] = [];
  public cuencas: ICuenca[] = [];
  public divisiones: Division[] = [];

  public centrosUnidadNegocio: ICentroOperativo[] = [];
  public cuencasUnidadNegocio: ICuenca[] = [];
  public gruposUnidadNegocio: IGrupo[] = [];
  public localidadsCentroOperativo: ILocalidad[] = [];

  public estados = ['Operativa', 'En Mantenimiento'];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public localidads$?: Subscription;
  public grupos$?: Subscription;
  public agrupaciones$?: Subscription;
  public correctoras$?: Subscription;
  public puntosMedicion$?: Subscription;
  public presion$?: Subscription;
  public scadas$?: Subscription;
  public medidores$?: Subscription;
  public cuencas$?: Subscription;

  // Mapa
  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptionsEditor),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPuntoMedicion,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarPuntosMedicionComponent>,
    private service: PuntosMedicionService,
    private listadosService: ListadosService,
    public helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? `Editar Punto de Medición ${this.data.nombre}`
      : 'Crear Punto de Medición';
    this.form = this.fb.group({
      // Detalles
      nombre: [this.data?.nombre, Validators.required],
      descripcion: [this.data?.descripcion],
      codigoSimec: [this.data?.codigoSimec],
      // GPS
      direccion: [this.data?.direccion],
      localidad: [this.data?.localidad],
      // Correctora
      idCorrectora: [this.data?.idCorrectora],
      fechaAsignacionCorrectora: [
        this.data?.fechaAsignacionCorrectora || new Date(),
      ],
      // Presion
      idUnidadPresion: [this.data?.idUnidadPresion],
      fechaAsignacionUnidadPresion: [
        this.data?.fechaAsignacionUnidadPresion || new Date(),
      ],
      // Medidor Residencial
      idMedidorResidencial: [this.data?.idMedidorResidencial],
      fechaAsignacionMedidorResidencial: [
        this.data?.fechaAsignacionMedidorResidencial || new Date(),
      ],
      // Medidor Residencial
      idsScada: [this.data?.idsScada],
      fechaAsignacionScada: [this.data?.fechaAsignacionScada || new Date()],
      // Tenancy
      idUnidadNegocio: [this.data?.idUnidadNegocio],
      idCentroOperativo: [this.data?.idCentroOperativo],
      idLocalidad: [this.data?.idLocalidad],
      idCuenca: [this.data?.idCuenca],
      idsGrupos: [this.data?.idsGrupos || []],
      idsAgrupaciones: [this.data?.idsAgrupaciones || []],
      division: [this.data?.division || 'Correctoras'],
      estado: [this.data?.estado || 'Operativa'],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  public nombreCorrectora(c: ICorrectora) {
    return `${c.modelo} S/N: ${c.numeroSerie} - ${c.dispositivo?.deviceName}`;
  }

  public nombreUnidadPresion(c: IUnidadPresion) {
    return `${c.modelo} S/N: ${c.numeroSerie} - ${c.dispositivo?.deviceName}`;
  }

  public nombreScada(c: IScada) {
    return `${c.tag} - ${c.nombre}`;
  }

  public nombreMedidor(c: IMedidorResidencial) {
    return `${c.nombre || 'Sin nombre'} S/N: ${c.deviceMeterNumber} - ${c
      .dispositivo?.deviceName}`;
  }

  private getCreateData() {
    const data: ICreatePuntoMedicion = this.form?.value;
    if (this.ubicacion) {
      data.ubicacion = this.ubicacion;
    }
    if (data.division === 'SCADA') {
      data.posicion = 9999;
    }
    return data;
  }

  private getUpdateData() {
    const data: IUpdatePuntoMedicion = this.form?.value;
    if (this.ubicacion) {
      data.ubicacion = this.ubicacion;
    }
    if (data.division === 'SCADA' && !this.data?.posicion) {
      data.posicion = 9999;
    }
    return data;
  }

  private getDataUpdateCorrectora() {
    const idCorrectora = this.form?.value?.idCorrectora;
    if (idCorrectora) {
      const correctora = this.correctoras.find((c) => c._id === idCorrectora);

      const estadoActualCorrectora = correctora?.estadoActual;
      const estadoActualNuevo = this.form?.value?.estado;

      if (estadoActualCorrectora !== estadoActualNuevo) {
        const data: IUpdateCorrectora = {
          estadoActual: estadoActualNuevo,
        };
        return data;
      }
    }
    return;
  }

  private getDataUpdatePresion() {
    const idUnidadPresion = this.form?.value?.idUnidadPresion;
    if (idUnidadPresion) {
      const presion = this.presions.find((c) => c._id === idUnidadPresion);

      const estadoActualCorrectora = presion?.estadoActual;
      const estadoActualNuevo = this.form?.value?.estado;

      if (estadoActualCorrectora !== estadoActualNuevo) {
        const data: IUpdateUnidadPresion = {
          estadoActual: estadoActualNuevo,
        };
        return data;
      }
    }
    return;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      const idCorrectora = this.form?.value?.idCorrectora;
      const idPresion = this.form?.value?.idUnidadPresion;
      if (this.data?._id) {
        // Editar
        const data = this.getUpdateData();
        const data2 = this.getDataUpdateCorrectora();
        const data3 = this.getDataUpdatePresion();

        await Promise.all([
          firstValueFrom(this.service.editar(this.data._id, data)),
          data2
            ? firstValueFrom(this.service.editarCorrectora(idCorrectora, data2))
            : null,
          data3
            ? firstValueFrom(this.service.editarPresion(idPresion, data3))
            : null,
        ]);
        this.helper.notifSuccess('Editado correctamente');
      } else {
        // Crear
        const data = this.getCreateData();
        const data2 = this.getDataUpdateCorrectora();
        const data3 = this.getDataUpdatePresion();

        await Promise.all([
          firstValueFrom(this.service.crear(data)),
          data2
            ? firstValueFrom(this.service.editarCorrectora(idCorrectora, data2))
            : null,
          data3
            ? firstValueFrom(this.service.editarPresion(idPresion, data3))
            : null,
        ]);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  //

  public setDatosUnidadNegocioActual() {
    //
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    const centrosUnidadNegocio = this.centroOperativos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );
    const cuencasUnidadNegocio = this.cuencas.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );
    const gruposUnidadNegocio = this.grupos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );

    this.centrosUnidadNegocio = centrosUnidadNegocio;
    this.cuencasUnidadNegocio = cuencasUnidadNegocio;
    this.gruposUnidadNegocio = gruposUnidadNegocio;
  }

  public setDatosCentroOperativoActual() {
    //
    const idCentroOperativo = this.form?.get('idCentroOperativo')?.value;
    const localidadsCentroOperativo = this.localidads.filter(
      (c) => c.idCentroOperativo === idCentroOperativo,
    );

    this.localidadsCentroOperativo = localidadsCentroOperativo;
  }

  public cambioUnidadNegocio() {
    //

    const idUnidadNegocio = this.form?.value?.idUnidadNegocio;
    this.setDatosUnidadNegocioActual();

    if (this.centrosUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCentroOperativo: this.centrosUnidadNegocio[0]._id,
      });
    } else {
      const idCentroSeleccionado = this.form?.value.idCentroOperativo;
      const centroSeleccionado = this.centrosUnidadNegocio.find(
        (c) => c._id === idCentroSeleccionado,
      );
      if (centroSeleccionado?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCentroOperativo: null,
        });
      }
    }
    this.setDatosCentroOperativoActual();

    const idCentroOperativo = this.form?.value.idCentroOperativo;
    if (this.localidadsCentroOperativo.length === 1) {
      this.form?.patchValue({
        idLocalidad: this.localidadsCentroOperativo[0]._id,
      });
    } else {
      const idLocalidadSeleccionada = this.form?.value.idLocalidad;
      const localidadSeleccionada = this.localidadsCentroOperativo.find(
        (c) => c._id === idLocalidadSeleccionada,
      );
      if (localidadSeleccionada?.idCentroOperativo !== idCentroOperativo) {
        this.form?.patchValue({
          idLocalidad: null,
        });
      }
    }

    if (this.cuencasUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCuenca: this.cuencasUnidadNegocio[0]._id,
      });
    } else {
      const idCuencaSeleccionada = this.form?.value.idCuenca;
      const cuencaSeleccionada = this.cuencasUnidadNegocio.find(
        (c) => c._id === idCuencaSeleccionada,
      );
      if (cuencaSeleccionada?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCuenca: null,
        });
      }
    }

    const idsGrupos = [];
    if (this.form?.value.idsGrupos) {
      for (const idGrupo of this.form.value.idsGrupos) {
        const grupoSeleccionado = this.grupos.find((c) => c._id === idGrupo);
        if (grupoSeleccionado?.idUnidadNegocio === idUnidadNegocio) {
          idsGrupos.push(idGrupo);
        }
      }
    }
    this.form?.patchValue({
      idsGrupos,
    });
  }

  public cambioCentroOperativo() {
    const idCentroOperativo = this.form?.value.idCentroOperativo;
    this.setDatosCentroOperativoActual();

    if (this.localidadsCentroOperativo.length === 1) {
      this.form?.patchValue({
        idLocalidad: this.localidadsCentroOperativo[0]._id,
      });
    } else {
      const idLocalidadSeleccionada = this.form?.value.idLocalidad;
      const localidadSeleccionada = this.localidadsCentroOperativo.find(
        (c) => c._id === idLocalidadSeleccionada,
      );
      if (localidadSeleccionada?.idCentroOperativo !== idCentroOperativo) {
        this.form?.patchValue({
          idLocalidad: null,
        });
      }
    }
  }

  public cambioCorrectora() {
    //
    const idCorrectora = this.form?.value.idCorrectora;
    const correctora = this.correctoras.find((c) => c._id === idCorrectora);
    const idUnidadNegocio = correctora?.idUnidadNegocio;

    if (correctora) {
      this.form?.patchValue({
        idUnidadNegocio: correctora.idUnidadNegocio,
        idCentroOperativo: correctora.idCentroOperativo,
        estado: correctora.estadoActual || 'Operativa',
      });
    } else {
      // this.form?.patchValue({
      //   idUnidadNegocio: null,
      //   idCentroOperativo: null,
      // });
    }

    this.setDatosUnidadNegocioActual();

    if (this.cuencasUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCuenca: this.cuencasUnidadNegocio[0]._id,
      });
    } else {
      const idCuencaSeleccionada = this.form?.value.idCuenca;
      const cuencaSeleccionada = this.cuencasUnidadNegocio.find(
        (c) => c._id === idCuencaSeleccionada,
      );
      if (cuencaSeleccionada?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCuenca: null,
        });
      }
    }

    const idsGrupos = [];
    if (this.form?.value.idsGrupos) {
      for (const idGrupo of this.form.value.idsGrupos) {
        const grupoSeleccionado = this.grupos.find((c) => c._id === idGrupo);
        if (grupoSeleccionado?.idUnidadNegocio === idUnidadNegocio) {
          idsGrupos.push(idGrupo);
        }
      }
    }
    this.form?.patchValue({
      idsGrupos,
    });
  }

  public cambioPresion() {
    //
    const idUnidadPresion = this.form?.value.idUnidadPresion as string;
    const presion = this.presions.find((p) => p._id === idUnidadPresion);
    const idUnidadNegocio = presion?.idUnidadNegocio;

    if (presion) {
      this.form?.patchValue({
        idUnidadNegocio: presion.idUnidadNegocio,
        idCentroOperativo: presion.idCentroOperativo,
        estado: presion.estadoActual || 'Operativa',
      });
    } else {
      // this.form?.patchValue({
      //   idUnidadNegocio: null,
      //   idCentroOperativo: null,
      // });
    }

    this.setDatosUnidadNegocioActual();

    if (this.cuencasUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCuenca: this.cuencasUnidadNegocio[0]._id,
      });
    } else {
      const idCuencaSeleccionada = this.form?.value.idCuenca;
      const cuencaSeleccionada = this.cuencasUnidadNegocio.find(
        (c) => c._id === idCuencaSeleccionada,
      );
      if (cuencaSeleccionada?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCuenca: null,
        });
      }
    }

    const idsGrupos = [];
    if (this.form?.value.idsGrupos) {
      for (const idGrupo of this.form.value.idsGrupos) {
        const grupoSeleccionado = this.grupos.find((c) => c._id === idGrupo);
        if (grupoSeleccionado?.idUnidadNegocio === idUnidadNegocio) {
          idsGrupos.push(idGrupo);
        }
      }
    }
    this.form?.patchValue({
      idsGrupos,
    });
  }

  public cambioScada() {
    //
    const idsScada = this.form?.value.idsScada as string[];
    const scada = this.scadas.find((s) => idsScada.includes(s._id!));
    const idUnidadNegocio = scada?.idUnidadNegocio;

    if (scada) {
      this.form?.patchValue({
        idUnidadNegocio: scada.idUnidadNegocio,
        idCentroOperativo: scada.idCentroOperativo,
        estado: scada.estadoActual || 'Operativa',
      });
    } else {
      // this.form?.patchValue({
      //   idUnidadNegocio: null,
      //   idCentroOperativo: null,
      // });
    }

    this.setDatosUnidadNegocioActual();

    if (this.cuencasUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCuenca: this.cuencasUnidadNegocio[0]._id,
      });
    } else {
      const idCuencaSeleccionada = this.form?.value.idCuenca;
      const cuencaSeleccionada = this.cuencasUnidadNegocio.find(
        (c) => c._id === idCuencaSeleccionada,
      );
      if (cuencaSeleccionada?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCuenca: null,
        });
      }
    }

    const idsGrupos = [];
    if (this.form?.value.idsGrupos) {
      for (const idGrupo of this.form.value.idsGrupos) {
        const grupoSeleccionado = this.grupos.find((c) => c._id === idGrupo);
        if (grupoSeleccionado?.idUnidadNegocio === idUnidadNegocio) {
          idsGrupos.push(idGrupo);
        }
      }
    }
    this.form?.patchValue({
      idsGrupos,
    });
  }

  public cambioMedidor() {
    //
    const idMedidorResidencial = this.form?.value
      .idMedidorResidencial as string;
    const medidor = this.medidores.find((p) => p._id === idMedidorResidencial);
    const idUnidadNegocio = medidor?.idUnidadNegocio;

    if (medidor) {
      this.form?.patchValue({
        idUnidadNegocio: medidor.idUnidadNegocio,
        idCentroOperativo: medidor.idCentroOperativo,
        estado: medidor.estadoActual || 'Operativa',
      });
    }

    this.setDatosUnidadNegocioActual();

    if (this.cuencasUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCuenca: this.cuencasUnidadNegocio[0]._id,
      });
    } else {
      const idCuencaSeleccionada = this.form?.value.idCuenca;
      const cuencaSeleccionada = this.cuencasUnidadNegocio.find(
        (c) => c._id === idCuencaSeleccionada,
      );
      if (cuencaSeleccionada?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCuenca: null,
        });
      }
    }

    const idsGrupos = [];
    if (this.form?.value.idsGrupos) {
      for (const idGrupo of this.form.value.idsGrupos) {
        const grupoSeleccionado = this.grupos.find((c) => c._id === idGrupo);
        if (grupoSeleccionado?.idUnidadNegocio === idUnidadNegocio) {
          idsGrupos.push(idGrupo);
        }
      }
    }
    this.form?.patchValue({
      idsGrupos,
    });
  }

  public async buscarSimilares() {
    const nombre = this.form?.get('nombre')?.value;
    const resp = await firstValueFrom(this.service.getNombreSimilar(nombre));

    if (resp.distancia < 3) {
      this.puntoSimilar = `Atención! Existe un punto con nombre similar <strong>${resp.nombre}</strong>`;
    }

    // this.puntoSimilar = `Mas similar ${resp.nombre} - distancia ${resp.distancia}`;
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;

        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
          this.setDatosUnidadNegocioActual();
        }

        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarLocalidads(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
    };

    // Listado
    this.localidads$?.unsubscribe();
    this.localidads$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidads = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  private async listarGrupos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarAgrupaciones(): Promise<void> {
    if (!this.helper.puedeVerAgrupaciones()) return;

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.agrupaciones$?.unsubscribe();
    this.agrupaciones$ = this.listadosService
      .subscribe<IListado<IAgrupacion>>('agrupacions', query)
      .subscribe((data) => {
        this.agrupaciones = data.datos;
        console.log(`listado de agrupacions`, data);
      });
    await this.listadosService.getLastValue('agrupacions', query);
  }

  private async listarCuencas(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuencas = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private async listarCorrectoras(): Promise<void> {
    if (!this.helper.puedeVerCorrectoras()) return;

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName',
    };
    const query: IQueryParam = {
      select:
        'modelo numeroSerie estadoActual deveui idUnidadNegocio idCentroOperativo',
      sort: 'modelo numeroSerie deviceName',
      populate: JSON.stringify(populate),
    };
    this.correctoras$?.unsubscribe();
    this.correctoras$ = this.listadosService
      .subscribe<IListado<ICorrectora>>('correctoras', query)
      .subscribe((data) => {
        const correctoras = data.datos as CorrectoraSelect[];

        correctoras.forEach((c) => {
          c.nombre = this.nombreCorrectora(c);
        });

        this.correctoras = correctoras;
        console.log(`listado de correctoras`, data);
      });
    await this.listadosService.getLastValue('correctoras', query);
  }

  private async listarPresion(): Promise<void> {
    if (!this.helper.puedeVerUnidadPresion()) return;

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName',
    };
    const query: IQueryParam = {
      select:
        'modelo numeroSerie estadoActual deveui idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.presion$?.unsubscribe();
    this.presion$ = this.listadosService
      .subscribe<IListado<IUnidadPresion>>('unidadPresions', query)
      .subscribe((data) => {
        const unidadesPresion = data.datos as UnidadPresionSelect[];
        unidadesPresion.forEach((c) => {
          c.nombre = this.nombreUnidadPresion(c);
        });
        this.presions = unidadesPresion;
        console.log(`listado de unidadPresions`, data);
      });
    await this.listadosService.getLastValue('unidadPresions', query);
  }

  private async listarScadas(): Promise<void> {
    if (!this.helper.puedeVerScada()) return;

    const query: IQueryParam = {
      select: 'tag nombre estadoActual idUnidadNegocio idCentroOperativo',
      sort: 'tag',
    };
    this.scadas$?.unsubscribe();
    this.scadas$ = this.listadosService
      .subscribe<IListado<IScada>>('scadas', query)
      .subscribe((data) => {
        const scadas = data.datos as ScadaSelect[];
        scadas.forEach((c) => {
          c.nombre = this.nombreScada(c);
        });
        this.scadas = scadas;
        console.log(`listado de scadas`, data);
      });
    await this.listadosService.getLastValue('scadas', query);
  }

  private async listarMedidores(): Promise<void> {
    if (!this.helper.puedeVerMedidores()) return;

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName',
    };
    const query: IQueryParam = {
      select:
        'nombre deviceMeterNumber estadoActual deveui idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.medidores$?.unsubscribe();
    this.medidores$ = this.listadosService
      .subscribe<IListado<IMedidorResidencial>>('medidorResidencials', query)
      .subscribe((data) => {
        data.datos.forEach((c) => {
          c.nombre = this.nombreMedidor(c);
        });
        this.medidores = data.datos;
        console.log(`listado de medidorResidencial`, data);
      });
    await this.listadosService.getLastValue('medidorResidencials', query);
  }

  private async listarPuntoByIdCorrectora(id: string) {
    const filter: IFilter<IPuntoMedicion> = {
      idCorrectora: id,
    };
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };
    this.puntosMedicion$?.unsubscribe();
    this.puntosMedicion$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntosMedicion = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  private async listarPuntoByIdUnidadPresion(id: string) {
    const filter: IFilter<IPuntoMedicion> = {
      idUnidadPresion: id,
    };
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };
    this.puntosMedicion$?.unsubscribe();
    this.puntosMedicion$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntosMedicion = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  private async listarPuntoByIdsScada(ids: string[]) {
    const filter: IFilter<IPuntoMedicion> = {
      $and: [{ idsScada: { $in: ids } }, { idsScada: { $exists: true } }],
    };
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };
    this.puntosMedicion$?.unsubscribe();
    this.puntosMedicion$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntosMedicion = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  private async listarPuntoByIdMedidorResidencial(id: string) {
    const filter: IFilter<IPuntoMedicion> = {
      idMedidorResidencial: id,
    };
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };
    this.puntosMedicion$?.unsubscribe();
    this.puntosMedicion$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntosMedicion = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  // Mapa

  public async selectDireccion(direccion: string) {
    this.form?.patchValue({ direccion });
  }

  public setLocation(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();
    if (coords) {
      this.ubicacion!.lat = coords.lat;
      this.ubicacion!.lng = coords.lng;
    }
  }

  private async initMap() {
    // Definir mapa
    this.mapCenter = {
      lat:
        this.data?.ubicacion?.lat ||
        this.ubicacion?.lat ||
        this.ubicacionDispositivo?.lat ||
        -35,
      lng:
        this.data?.ubicacion?.lng ||
        this.ubicacion?.lng ||
        this.ubicacionDispositivo?.lng ||
        -71,
    };
    // Definir marcador
    this.markerOptions.position = this.mapCenter;
  }

  public stringToCoord() {
    const coord = this.coord?.split(',');

    if (coord?.length === 2) {
      const lat = +coord![0];
      const lng = +coord![1];

      this.markerOptions = {
        draggable: true,
        position: { lat, lng },
      };
      this.mapCenter = { lat, lng };
      this.ubicacion = { lat, lng };
    }
    console.log('coord', this.ubicacion);
  }

  //

  private setDivisiones() {
    console.log(`Set Divisiones`);

    if (this.helper.puedeVerCorrectoras()) this.divisiones.push('Correctoras');
    if (this.helper.puedeVerUnidadPresion()) this.divisiones.push('Presión');
    if (this.helper.puedeVerResidencial()) this.divisiones.push('Residencial');
    if (this.helper.puedeVerScada()) this.divisiones.push('SCADA');

    console.log('divisiones', this.divisiones);

    if (this.divisiones.length === 1) {
      this.form?.patchValue({
        division: this.divisiones[0],
      });
    }
  }

  // Chequear Correctora Asignada
  public async checkCorrectoraAsignada() {
    try {
      this.asignada = false;
      const idCorrectora = this.form?.get('idCorrectora')?.value;
      if (idCorrectora) {
        await this.listarPuntoByIdCorrectora(idCorrectora);
        if (this.puntosMedicion.length > 0) {
          this.asignada = true;
          this.textoAsignado = `* La correctora seleccionada está asignada al Punto de Medición: <strong>${this.puntosMedicion[0].nombre}</strong>, si continúa será desasignada del mismo para asignarase a este punto.`;
        } else {
          this.asignada = false;
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async checkPresionAsignada() {
    try {
      this.asignada2 = false;
      const idPresion = this.form?.get('idUnidadPresion')?.value;
      if (idPresion) {
        await this.listarPuntoByIdUnidadPresion(idPresion);
        if (this.puntosMedicion.length > 0) {
          this.asignada2 = true;
          this.textoAsignado2 = `* La unidad de presión seleccionada está asignada al Punto de Medición: <strong>${this.puntosMedicion[0].nombre}</strong>, si continúa será desasignada del mismo para asignarase a este punto.`;
        } else {
          this.asignada2 = false;
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async checkScadaAsignada() {
    try {
      this.asignada4 = false;
      const idsScada = this.form?.get('idsScada')?.value as string[];
      if (idsScada) {
        await this.listarPuntoByIdsScada(idsScada);
        if (this.puntosMedicion.length > 0) {
          this.asignada4 = true;
          this.textoAsignado4 = `* Uno de los SCADAS está asignado al Punto de Medición: <strong>${this.puntosMedicion[0].nombre}</strong>, si continúa será desasignada del mismo para asignarase a este punto.`;
        } else {
          this.asignada4 = false;
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public async checkMedidorAsignado() {
    try {
      this.asignada3 = false;
      const idMedidor = this.form?.get('idMedidorResidencial')?.value;
      if (idMedidor) {
        await this.listarPuntoByIdMedidorResidencial(idMedidor);
        if (this.puntosMedicion.length > 0) {
          this.asignada3 = true;
          this.textoAsignado3 = `* El medidor seleccionado está asignado al Punto de Medición: <strong>${this.puntosMedicion[0].nombre}</strong>, si continúa será desasignada del mismo para asignarase a este punto.`;
        } else {
          this.asignada3 = false;
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  async ngOnInit(): Promise<void> {
    this.ubicacionDispositivo = await HelperService.getCurrentPosition();
    if (this.data?.ubicacion) {
      this.ubicacion = this.data.ubicacion;
    } else {
      this.ubicacion = this.ubicacionDispositivo;
    }
    this.createForm();
    await Promise.all([
      this.initMap(),
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.listarLocalidads(),
      this.listarGrupos(),
      this.listarAgrupaciones(),
      this.listarCorrectoras(),
      this.listarPresion(),
      this.listarMedidores(),
      this.listarCuencas(),
      this.listarScadas(),
    ]);
    if (this.data) {
      this.setDatosUnidadNegocioActual();
      this.setDatosCentroOperativoActual();

      const estado = this.data.estado;
      if (
        estado &&
        estado !== 'Sin Asignar' &&
        !this.estados.includes(estado)
      ) {
        this.estados.push(estado);
      }
    }

    this.setDivisiones();
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.localidads$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.agrupaciones$?.unsubscribe();
    this.correctoras$?.unsubscribe();
    this.puntosMedicion$?.unsubscribe();
    this.presion$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.medidores$?.unsubscribe();
    this.scadas$?.unsubscribe();
  }
}
