import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IAgrupacion,
  ICentroOperativo,
  ICorrectora,
  ICuenca,
  IDispositivoNsp4G,
  IDispositivoNuc4G,
  IDispositivoVeriboxMicro,
  IFilter,
  IGrupo,
  IListado,
  ILocalidad,
  IMedidorResidencial,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
  IReporteScada,
  IReporteSML,
  IReporteWRC,
  IScada,
  IUnidadPresion,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarPuntosMedicionComponent } from '../crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';
import { PuntosMedicionService } from '../service';
import { UbicarService } from 'src/app/auxiliares/ubicar/ubicar.service';
import {
  IFiltroGlobal,
  NavigationService,
} from '../../navigation/navigation.service';
import {
  IColumnas,
  ICrearBoton,
  IExportarBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import { Router } from '@angular/router';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';
import { IReporteVeribox } from 'modelos/src';

type ICorrectoraFiltro = ICorrectora & { nombre: string };

type IScadaFiltro = IScada & { nombre: string };

@Component({
  selector: 'app-listado-puntos-medicion',
  templateUrl: './listado-puntos-medicion.component.html',
  styleUrls: ['./listado-puntos-medicion.component.scss'],
})
export class ListadoPuntosMedicionComponent implements OnInit, OnDestroy {
  public filtroGlobal: IFiltroGlobal = {};

  // Filtros Tabla
  private centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private localidad: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idLocalidad',
    },
    label: 'Localidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private cuenca: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCuenca',
    },
    label: 'Zona de Calidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsGrupos',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private correctora: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCorrectora',
    },
    label: 'Correctora',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private division: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'division',
    },
    label: 'División',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  private agrupacion: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsAgrupaciones',
    },
    label: 'Agrupación',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private unidadPresion: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUnidadPresion',
    },
    label: 'Unidad de Presión',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private scadas: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsScada',
    },
    label: 'SCADAS',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private medidorResidencial: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idMedidorResidencial',
    },
    label: 'Medidor Residencial',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private estado: IFiltroTabla = {
    elementos: [
      {
        label: 'Operativa',
      },
      {
        label: 'Sin Reportar',
      },
      {
        label: 'Resolver',
      },
      {
        label: 'En Mantenimiento',
      },
      {
        label: 'Alerta',
      },
      {
        label: 'Sin Asignar',
      },
    ],
    filter: {
      field: 'estado',
    },
    label: 'Estado',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.estado,
    this.centroOperativo,
    this.localidad,
    this.cuenca,
    this.grupo,
    this.agrupacion,
  ];

  //// Tabla nueva
  public name = 'ListadoPuntosMedicionComponent';
  public datos?: IPuntoMedicion[] = [];
  public columnas?: IColumnas<IPuntoMedicion>[];
  public search: IRegExpSearch = {
    fields: ['nombre', 'localidad'],
  };
  public totalCount = 0;

  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeCrearPuntosDeMedicion(),
    tooltip: 'Crear Punto de Medición',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  public botonExportar: IExportarBoton = {
    mostrar: true,
    tooltip: 'Exportar',
    color: 'accent',
    icon: 'exportar',
    accion: () => this.exportar(),
  };

  // QUERY+
  private populate = [
    {
      path: 'correctora',
      select:
        'modelo numeroSerie estadoActual ultimoRegistro ultimaAlerta deveui bateria',
      populate: {
        path: 'dispositivo',
        select: 'deviceName config',
      },
    },
    {
      path: 'unidadPresion',
      select:
        'modelo numeroSerie estadoActual ultimoRegistro ultimaAlerta deveui',
      populate: {
        path: 'dispositivo',
        select: 'deviceName config tipoDispositivo',
      },
    },
    {
      path: 'medidorResidencial',
      select: 'modelo deviceMeterNumber estadoActual ultimoReporte deveui',
      populate: {
        path: 'dispositivo',
        select: 'deviceName config tipoDispositivo',
      },
    },
    {
      path: 'scadas',
    },
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
    {
      path: 'cuenca',
      select: 'nombre',
    },
    {
      path: 'grupos',
      select: 'nombre',
    },
    {
      path: 'agrupaciones',
      select: 'nombre',
    },
    {
      path: 'localidad2',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public unidadNegocios$?: Subscription;
  public unidadNegocio$?: Subscription;
  public centroOperativos$?: Subscription;
  public localidads$?: Subscription;
  public grupos$?: Subscription;
  public agrupacions$?: Subscription;
  public correctoras$?: Subscription;
  public cuencas$?: Subscription;
  public unidadPresions$?: Subscription;
  public dispositivos$?: Subscription;
  public medidorResidencials$?: Subscription;
  public scadas$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: PuntosMedicionService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    private ubicarService: UbicarService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  // ACCIONES
  public async detalles(d: IPuntoMedicion) {
    await this.router.navigate(['main', 'puntosMedicion', `${d._id}`]);
  }

  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async editar(data: IPuntoMedicion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async eliminar(dato: IPuntoMedicion): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el grupo ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de puntos?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje,
    );
    if (confirm) {
      try {
        await this.service.exportar();
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public abrirEnBrowser(punto: IPuntoMedicion) {
    const c = punto.ubicacion;
    if (!c) return;
    const url = `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`;
    window.open(url, '_blank');
  }

  // LISTADOS

  private setFiltros() {
    if (this.helper.puedeVerCorrectoras()) {
      this.division?.elementos?.push({
        label: 'Correctoras',
        value: 'Correctoras',
      });
      this.filtros.push(this.correctora);
    }
    if (this.helper.puedeVerUnidadPresion()) {
      this.division?.elementos?.push({
        label: 'Presión',
        value: 'Presión',
      });
      this.filtros.push(this.unidadPresion);
    }
    if (this.helper.puedeVerMedidores()) {
      this.division?.elementos?.push({
        label: 'Residencial',
        value: 'Residencial',
      });
      this.filtros.push(this.medidorResidencial);
    }
    if (this.helper.puedeVerScada()) {
      this.division?.elementos?.push({
        label: 'SCADA',
        value: 'SCADA',
      });
      this.filtros.push(this.scadas);
    }

    //
    const divisiones = this.division?.elementos?.length || 0;
    if (divisiones > 1) {
      this.filtros.unshift(this.division);
    }
  }

  private suscribeFiltroGlobal() {
    this.unidadNegocio$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.filtroGlobal = filtro;
        this.listar();
      });
    this.filtroGlobal = this.navigationService.getFiltroGlobal();
  }

  public async listar(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<IPuntoMedicion> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    if (this.filtroGlobal.division) {
      filter.division = this.filtroGlobal.division;
    } else {
      delete filter.division;
    }
    this.query.filter = JSON.stringify(filter);

    // Listado
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', this.query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Filtro
    const filter: IFilter<ICentroOperativo> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativo.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICentroOperativo[];
        this.centroOperativo.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarLocalidades(): Promise<void> {
    // Filtro
    const filter: IFilter<ILocalidad> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.localidads$?.unsubscribe();
    this.localidads$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidad.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as ILocalidad[];
        this.localidad.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  private async listarGrupos(): Promise<void> {
    // Filtro
    const filter: IFilter<IGrupo> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    if (this.filtroGlobal.division) {
      filter.division = this.filtroGlobal.division;
    } else {
      delete filter.division;
    }
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listadosService
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as IGrupo[];
        this.grupo.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: [] },
        });
        console.log(`listado de grupos`, data);
      });
    await this.listadosService.getLastValue('grupos', query);
  }

  private async listarAgrupaciones(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };

    // Listado
    this.agrupacions$?.unsubscribe();
    this.agrupacions$ = this.listadosService
      .subscribe<IListado<IAgrupacion>>('agrupacions', query)
      .subscribe((data) => {
        this.agrupacion.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as IAgrupacion[];
        this.agrupacion.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: [] },
        });
        console.log(`listado de agrupacions`, data);
      });
    await this.listadosService.getLastValue('agrupacions', query);
  }

  private async listarCuencas(): Promise<void> {
    // Filtro
    const filter: IFilter<ICuenca> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuenca.elementos = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICuenca[];
        this.cuenca.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private async listarCorrectoras(): Promise<void> {
    if (!this.helper.puedeVerCorrectoras()) return;

    // Filtro
    const filter: IFilter<ICorrectora> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName config.firmwareNuc',
    };
    const query: IQueryParam = {
      select: 'modelo numeroSerie deveui',
      sort: 'modelo numeroSerie',
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
    };

    // Listado
    this.correctoras$?.unsubscribe();
    this.correctoras$ = this.listadosService
      .subscribe<IListado<IGrupo>>('correctoras', query)
      .subscribe((data) => {
        console.log(`listado de correctoras`, data);

        const correctoras = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICorrectoraFiltro[];

        correctoras.forEach((correctora) => {
          const config = correctora?.dispositivo?.config as IDispositivoNuc4G;
          const firmware = config?.firmwareNuc;
          correctora.nombre = `${correctora.modelo} - ${correctora.numeroSerie} (${correctora?.dispositivo?.deviceName})`;

          if (firmware) {
            correctora.nombre += ` ${firmware}`;
          }
        });

        this.correctora.elementos = correctoras;

        this.correctora.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
      });
    await this.listadosService.getLastValue('correctoras', query);
  }

  private async listarPresion(): Promise<void> {
    if (!this.helper.puedeVerUnidadPresion()) return;

    // Filtro
    const filter: IFilter<IUnidadPresion> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName config.firmwareNsp config.telefono',
    };
    const query: IQueryParam = {
      select: 'modelo numeroSerie deveui',
      sort: 'modelo numeroSerie',
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
    };

    // Listado
    this.unidadPresions$?.unsubscribe();
    this.unidadPresions$ = this.listadosService
      .subscribe<IListado<IUnidadPresion>>('unidadPresions', query)
      .subscribe((data) => {
        console.log(`listado de unidadPresions`, data);

        const presions = JSON.parse(
          JSON.stringify(data.datos),
        ) as ICorrectoraFiltro[];

        presions.forEach((p) => {
          p.nombre = `${p.modelo} - ${p.numeroSerie} (${p?.dispositivo?.deviceName})`;

          // Firmware NSP
          const config = p?.dispositivo?.config as IDispositivoNsp4G;
          const firmware = config?.firmwareNsp;
          if (firmware) {
            p.nombre += ` ${firmware}`;
          }

          // Telefono veribox
          const tel = (p?.dispositivo?.config as IDispositivoVeriboxMicro)
            ?.telefono;
          if (tel) {
            p.nombre += ` Tel: ${tel}`;
          }
        });

        this.unidadPresion.elementos = presions;

        this.unidadPresion.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
      });
    await this.listadosService.getLastValue('unidadPresions', query);
  }

  private async listarScadas(): Promise<void> {
    if (!this.helper.puedeVerScada()) return;

    // Filtro
    const filter: IFilter<IScada> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const query: IQueryParam = {
      select: 'tag nombre',
      sort: 'tag nombre',
      filter: JSON.stringify(filter),
    };

    // Listado
    this.scadas$?.unsubscribe();
    this.scadas$ = this.listadosService
      .subscribe<IListado<IScada>>('scadas', query)
      .subscribe((data) => {
        console.log(`listado de scadas`, data);

        const scadas = JSON.parse(JSON.stringify(data.datos)) as IScadaFiltro[];

        scadas.forEach((s) => {
          s.nombre = `${s.tag} - ${s.nombre}`;
        });

        this.scadas.elementos = scadas;

        this.scadas.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
      });
    await this.listadosService.getLastValue('scadas', query);
  }

  private async listarMedidorResidencials(): Promise<void> {
    if (!this.helper.puedeVerMedidores()) return;

    // Filtro
    const filter: IFilter<IUnidadPresion> = {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const populate: IPopulate = {
      path: 'dispositivo',
      select: 'deviceName',
    };
    const query: IQueryParam = {
      select: 'nombre deviceMeterNumber deveui',
      sort: 'nombre deviceMeterNumber',
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
    };

    // Listado
    this.medidorResidencials$?.unsubscribe();
    this.medidorResidencials$ = this.listadosService
      .subscribe<IListado<IMedidorResidencial>>('medidorResidencials', query)
      .subscribe((data) => {
        console.log(`listado de medidorResidencials`, data);

        const medidores = JSON.parse(
          JSON.stringify(data.datos),
        ) as IMedidorResidencial[];

        medidores.forEach((p) => {
          p.nombre = `${p.nombre} - ${p.deviceMeterNumber} (${p?.dispositivo?.deviceName})`;
        });

        this.medidorResidencial.elementos = medidores;
        this.medidorResidencial.elementos.unshift({
          nombre: 'Sin Asignar',
          _id: { $eq: null },
        });
      });
    await this.listadosService.getLastValue('medidorResidencials', query);
  }

  // CREACION DE LA TABLA

  private setColumnas() {
    this.columnas = [
      // Estado
      {
        header: {
          label: 'Estado',
          sortable: true,
          sort: 'estado',
        },
        row: {
          icono: (dato) => this.helper.iconEstadoPuntoMedicion(dato),
        },
      },
      // Nombre / Punto de Medición
      {
        header: {
          label: 'Punto de Medición',
          sortable: true,
          sort: 'nombre',
        },
        row: {
          html: (dato) => this.parseNombre(dato),
        },
      },
      // Dispositivo
      {
        header: {
          label: 'Dispositivo',
        },
        row: {
          html: (dato) => this.parseDispositivo(dato),
        },
      },
      // Operadora
      {
        header: {
          label: 'Operadora',
        },
        row: {
          html: (dato) => this.parseOperadora(dato),
        },
      },
      // Fecha Último Registro
      {
        header: {
          label: 'Fecha Último Registro',
        },
        row: {
          html: (dato) => this.parseFechaUltimoRegistro(dato),
        },
      },
      // Último Estado
      {
        header: {
          label: 'Último Estado',
        },
        row: {
          html: (dato) => this.parseUltimoEstado(dato),
        },
      },
      // Unidad de Negocio
      {
        header: {
          label: 'Unidad de Negocio',
        },
        row: {
          html: (dato) => this.parseUnidadDeNegocio(dato),
        },
      },
      // Localidad
      {
        header: {
          label: 'Localidad',
        },
        row: {
          html: (dato) => this.parseLocalidad(dato),
          noData: 'Sin asignar',
        },
      },
      // Zona de Calidad
      {
        header: {
          label: 'Zona de Calidad',
        },
        row: {
          html: (dato) => this.parseZonaCalidad(dato),
        },
      },
      // Grupos
      {
        header: {
          label: 'Grupos',
        },
        row: {
          html: (dato) => this.parseGrupos(dato),
        },
      },
      // Agrupaciones
      {
        header: {
          label: 'Agrupaciones',
        },
        row: {
          html: (dato) => this.parseAgrupaciones(dato),
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: async (dato) => this.detalles(dato),
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              oculta: (dato) => !this.helper.puedeEditarPuntosDeMedicion(dato),
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              oculta: (dato) =>
                !this.helper.puedeEliminarPuntosDeMedicion(dato),
              click: (dato) => this.eliminar(dato),
            },
            {
              tipo: 'img',
              icon: 'assets/iconos/directions.png',
              tooltip: 'Abrir en Maps',
              click: async (dato) => this.abrirEnBrowser(dato),
            },
          ],
        },
      },
    ];
  }

  private parseNombre(p: IPuntoMedicion) {
    const nombre = p.nombre;
    const division = p.division || 'Sin asignar';

    let html = `
    <div class="flex-column mobile-align-right">
      <div> ${nombre} </div>
      <div class="no-info">${division}</div>
    `;

    html += '</div>';

    return html;
  }

  private parseDispositivo(p: IPuntoMedicion) {
    if (
      !p.correctora &&
      !p.unidadPresion &&
      !p.medidorResidencial &&
      !p.scadas
    ) {
      return `<div class="no-info">Sin asignar</div>`;
    }

    if (p.division === 'SCADA') {
      let html = `<div class="flex-column mobile-align-right">`;
      for (const s of p.scadas!) {
        html += `<div>${s.tag} - ${s.nombre}</div>`;
      }
      html += '</div>';
      return html;
    }

    const modelo =
      p.correctora?.modelo ||
      p.unidadPresion?.modelo ||
      p.medidorResidencial?.nombre;
    const numeroSerie =
      p.correctora?.numeroSerie ||
      p.unidadPresion?.numeroSerie ||
      p.medidorResidencial?.deviceMeterNumber;
    const deviceName =
      p.correctora?.dispositivo?.deviceName ||
      p.unidadPresion?.dispositivo?.deviceName ||
      p.medidorResidencial?.dispositivo?.deviceName;

    const reporteWRC = p.medidorResidencial?.ultimoReporte
      ?.valores as IReporteWRC;
    // const reporteSML = p.medidorResidencial?.ultimoReporte
    //   ?.valores as IReporteSML;
    const firmware =
      p.correctora?.dispositivo?.config?.firmwareNuc ||
      p.unidadPresion?.dispositivo?.config?.firmwareNsp ||
      reporteWRC?.device_information?.software_version;

    let html = `<div class="flex-column mobile-align-right">`;

    html += modelo
      ? `<div> ${modelo} </div>`
      : `<div class="no-info">Sin nombre</div>`;

    html += `
    <div class="no-info">${numeroSerie}</div>
      <div>${deviceName}</div>
    `;

    if (firmware) {
      html += `<div>${firmware}</div>`;
    }

    if (p.unidadPresion?.dispositivo?.tipoDispositivo === 'VERIBOX MICRO') {
      const telefono = (
        p.unidadPresion?.dispositivo?.config as IDispositivoVeriboxMicro
      )?.telefono;
      if (telefono) {
        html += `<div class="no-info">${telefono}</div>`;
      } else {
        html += ` <div class="no-info">Sin teléfono</div>`;
      }
    }

    html += '</div>';

    return html;
  }

  private parseFechaUltimoRegistro(p: IPuntoMedicion): string {
    const fecha =
      p.correctora?.ultimoRegistro?.timestamp ||
      p.unidadPresion?.ultimoRegistro?.valores?.timestamp ||
      p.medidorResidencial?.ultimoReporte?.fechaCreacion ||
      p.scadas?.[0]?.ultimoRegistro?.valores?.timestamp;

    if (fecha) {
      return `<div>${new Date(fecha).toLocaleDateString()} - ${new Date(fecha)
        .toLocaleTimeString('es-AR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
        .slice(0, 5)}</div>`;
    } else {
      return `<div class="no-info">Sin reportar</div>`;
    }
  }

  private parseUltimoEstado(p: IPuntoMedicion): string {
    // Correctora
    const registro = p.correctora?.ultimoRegistro;
    if (registro) {
      const presion = registro.presion;
      const bateria = p.correctora?.bateria;
      let res = `<div>${presion} BAR</div>`;
      if (bateria) {
        res += `<div>${this.helper.bateriaCorrectora(p.correctora)}</div>`;
      } else {
        res += `<div class="no-info">Sin reportar batería</div>`;
      }
      return res;
    }

    // Presion
    const reporte = p.unidadPresion?.ultimoRegistro;
    if (reporte) {
      const valores = reporte.valores as IReporteVeribox;
      const unidad = valores?.unidad || 'BAR';
      const presion = valores?.presion;
      const bateria = valores?.bateria;

      let res = `<div>${presion} ${unidad}</div>`;
      if (bateria) {
        res += `<div>${this.helper.bateriaUnidadPresion(bateria)}</div>`;
      } else {
        res += `<div class="no-info">Sin reportar batería</div>`;
      }
      return res;
    }

    // Residenical
    const reporteResidencial = p.medidorResidencial?.ultimoReporte;
    if (reporteResidencial) {
      const valores = reporteResidencial?.valores as IReporteWRC | IReporteSML;
      const consumo = valores?.consumo;
      const bateria = valores?.bateria;

      let res = `<div>${consumo} m<sup>3</sup></div>`;
      if (bateria) {
        res += `<div>${bateria} v.</div>`;
      } else {
        res += `<div class="no-info">Sin reportar batería</div>`;
      }
      return res;
    }

    // SCADA
    const reporteScada = p.scadas?.[0]?.ultimoRegistro;
    if (reporteScada) {
      const valores = reporteScada.valores as IReporteScada;
      const unidad = valores?.tipo === 'Temperatura' ? '°C' : 'BAR';
      const presion = valores?.valorActual;
      return `<div>${presion} ${unidad}</div>`;
    }

    // Sin reportar
    return `<div class="no-info">Sin reportar</div>`;
  }

  private parseOperadora(p: IPuntoMedicion): string {
    // Correctora
    const correctora = p.correctora;
    if (correctora) {
      return `<div class="no-info">No aplica</div>`;
    }

    // Presion
    const unidadPresion = p.unidadPresion;
    if (unidadPresion) {
      const config = unidadPresion.dispositivo
        ?.config as IDispositivoVeriboxMicro;

      const apn = config?.apn;

      if (apn?.includes('claro')) {
        return `<div>Claro</div>`;
      }
      if (apn?.includes('movistar')) {
        return `<div>Movistar</div>`;
      }
      return `<div class="no-info">No identificado</div>`;
    }

    // Residencial
    const medidor = p.medidorResidencial;
    if (medidor) {
      return `<div class="no-info">No aplica</div>`;
    }

    // SCADAS
    const scadas = p.scadas;
    if (scadas) {
      return `<div class="no-info">No aplica</div>`;
    }

    // Sin reportar
    return `<div class="no-info">Sin reportar</div>`;
  }

  private parseUnidadDeNegocio(d: IPuntoMedicion): string {
    if (d.unidadNegocio) {
      let res = `<div>${d.unidadNegocio.nombre}</div>`;
      if (d.centroOperativo) {
        res += `<div>${d.centroOperativo.nombre}</div>`;
      } else {
        res += `<div class="no-info">Sin asignar</div>`;
      }
      return res;
    } else {
      return `<div class="no-info">Sin asignar</div>`;
    }
  }

  private parseZonaCalidad(d: IPuntoMedicion): string {
    if (d.division === 'Correctoras') {
      if (d.cuenca) {
        return `${d.cuenca.nombre}`;
      } else {
        return `<div class="no-info">Sin asignar</div>`;
      }
    } else {
      return `<div class="no-info">No aplica</div>`;
    }
  }

  private parseLocalidad(d: IPuntoMedicion): string {
    if (d.localidad2?.nombre) {
      return `${d.localidad2?.nombre}`;
    } else if (d.localidad) {
      return `<div style="color: red">${d.localidad}</div>`;
    } else {
      return `<div class="no-info">Sin asignar</div>`;
    }
  }

  private parseGrupos(d: IPuntoMedicion): string {
    if (!d.grupos || d.grupos.length < 1)
      return `<div class="no-info">Sin asignar</div>`;
    let grupos = '';
    if (d.grupos) {
      for (const g of d.grupos) {
        grupos += `<div>${g.nombre}</div>`;
      }
    }
    return grupos;
  }

  private parseAgrupaciones(d: IPuntoMedicion): string {
    if (!d.agrupaciones || d.agrupaciones.length < 1)
      return `<div class="no-info">Sin asignar</div>`;
    let agrupaciones = '';
    if (d.agrupaciones) {
      for (const g of d.agrupaciones) {
        agrupaciones += `<div>${g.nombre}</div>`;
      }
    }
    return agrupaciones;
  }

  ///

  async ngOnInit(): Promise<void> {
    this.suscribeFiltroGlobal();
    this.setColumnas();
    this.setFiltros();
    await Promise.all([
      this.listar(),
      this.listarCentrosOperativos(),
      this.listarLocalidades(),
      this.listarGrupos(),
      this.listarAgrupaciones(),
      this.listarCorrectoras(),
      this.listarCuencas(),
      this.listarPresion(),
      this.listarMedidorResidencials(),
      this.listarScadas(),
    ]);
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocio$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.grupos$?.unsubscribe();
    this.correctoras$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.unidadPresions$?.unsubscribe();
    this.dispositivos$?.unsubscribe();
    this.medidorResidencials$?.unsubscribe();
    this.scadas$?.unsubscribe();
  }
}
