<div class="column-center" style="overflow: hidden; width: 100%">
  <app-tabla
    [totalCount]="totalCount"
    [datos]="datos"
    [columnas]="columnas"
    [name]="name"
    [pageSizeOptions]="helper.pageSizeOptions"
    [search]="search"
    [filtros]="filtros"
    [(query)]="query"
    (queryChange)="listar()"
    [paginatorArriba]="false"
    [crear]="botonCrear"
    [exportar]="botonExportar"
  ></app-tabla>
</div>
