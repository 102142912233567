<div style="height: 100%; display: flex; flex-direction: column">
  <div class="titulo">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      Últimos Reportes
    </h1>
  </div>

  <mat-tab-group style="height: 100%">
    @for (s of scadas; track $index) {
      <mat-tab [label]="s.tag!">
        <app-ultimo-reporte-scada [scada]="s"></app-ultimo-reporte-scada>
      </mat-tab>
    }
  </mat-tab-group>
</div>
