import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IConfigDispositivoScada,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporte,
  IReporteScada,
  IScada,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../auxiliares/helper.service';
import { ListadosService } from '../../auxiliares/listados.service';
import {
  PointOptionsObject,
  SeriesLineOptions,
  SeriesOptionsType,
  YAxisOptions,
} from 'highcharts';
import { VERDE } from 'src/assets/estilos/colores';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LoadingService } from '../../auxiliares/loading.service';

const colorDatos = VERDE;

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-grafico-scada',
  templateUrl: './grafico-scada.component.html',
  styleUrls: ['./grafico-scada.component.scss'],
})
export class GraficoScadaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() scada?: IScada;
  @Input() range?: { from: string; to: string };

  // Reportes Historicos
  public chart?: Highcharts.Options;

  public reportes: IReporte[] = [];

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
    public loading: LoadingService,
  ) {}

  // ##############################################################################

  private getChartOptions(
    series: SeriesOptionsType[],
    yAxis?: YAxisOptions | YAxisOptions[],
  ) {
    const options: Highcharts.Options = {
      chart: {
        style: {
          background: 'transparent',
          backgroundColor: 'transparent',
          fontFamily: 'monserrat-regular, sans-serif',
        },
      },
      title: {
        align: 'left',
        text: undefined,
      },
      yAxis,
      xAxis: {
        type: 'datetime',
      },
      legend: {
        // enabled: false,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          marker: {
            // enabled: false,
          },
          label: {
            connectorAllowed: false,
          },
        },
      },
      series,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
    return options;
  }

  private getChartOptionsSimple(
    series: SeriesOptionsType[],
    text?: string,
    unidad?: string,
  ) {
    const config = this.scada?.config as IConfigDispositivoScada;
    const limiteLL = config?.limiteLL || 10;
    const limiteL = config?.limiteL || 30;
    const limiteH = config?.limiteH || 60;
    const limiteHH = config?.limiteHH || 90;

    const yAxis: YAxisOptions = {
      title: {
        text,
        style: {
          color: colorDatos,
        },
      },
      // m³
      labels: {
        format: unidad ? `{value} ${unidad}` : '{value}',
        style: {
          color: colorDatos,
        },
      },
      plotLines: [
        {
          value: limiteLL,
          color: '#f31616',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite LOLO ${(limiteLL ? limiteLL : 0).toFixed(
              2,
            )} ${unidad}`,
            style: {
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
        {
          value: limiteL,
          color: '#f15d5d',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite LO ${(limiteL ? limiteL : 0).toFixed(2)} ${unidad}`,
            style: {
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
        {
          value: limiteHH,
          color: '#faf60c',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite HIHI ${(limiteHH ? limiteHH : 0).toFixed(
              2,
            )} ${unidad}`,
            style: {
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
        {
          value: limiteH,
          color: '#f1e571',
          dashStyle: 'Dot',
          width: 3,
          label: {
            text: `Límite HI ${(limiteH ? limiteH : 0).toFixed(2)} ${unidad}`,
            style: {
              color: HelperService.esModoOscuro() ? 'white' : 'black',
            },
          },
        },
      ],
    };
    return this.getChartOptions(series, yAxis);
  }

  private async graficoHistorico(): Promise<void> {
    if (!this.reportes.length) {
      this.chart = undefined;
      return;
    }

    const series: SeriesLineOptions[] = [];

    const reportes: IReporte[] = JSON.parse(JSON.stringify(this.reportes));

    console.log(`Grafico REPORTES SCADA`, reportes);

    // TODO: reemplazar por offset del establecimiento
    const timeZoneOffset = -3;
    const timeZoneOffsetMs = 1000 * 60 * 60 * timeZoneOffset;

    // Crea las series del grafico
    const data: PointOptionsObject[] = [];

    const tipo = this.scada?.tipo;
    const unidad = tipo === 'Temperatura' ? '°C' : 'm³';
    for (const reporte of reportes) {
      const valores = reporte.valores as IReporteScada;
      const fecha = new Date(valores.timestamp!).getTime() + timeZoneOffsetMs;
      const valor = +(valores.valorActual || 0).toFixed(1);

      if (valor !== -1) {
        data.push({
          x: fecha,
          y: valor,
        });
      }
    }

    const serieValor: SeriesLineOptions = {
      type: 'line',
      name: tipo,
      data: data,
      color: colorDatos,
      // lineWidth: 3,
      // fillOpacity: 0.3,
      // gapSize: 1000 * 60 * 70, // 1:10 hora
      // gapUnit: 'value',
      tooltip: {
        xDateFormat: '%d-%m-%Y %H:%M',
        pointFormat: '<strong>{point.y}</strong>',
      },
    };

    series.push(serieValor);

    this.chart = this.getChartOptionsSimple(series, tipo, unidad);
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  private async listar(): Promise<void> {
    let filter: IFilter<any> | boolean = false;

    if (this.scada && this.range) {
      filter = {
        idsAsignados: this.scada._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
        'valores.valorActual': { $exists: true },
      };
    }
    if (this.puntoMedicion && this.range) {
      filter = {
        idsAsignados: this.puntoMedicion._id,
        'valores.timestamp': {
          $gte: this.range.from,
          $lte: this.range.to,
        },
        'valores.valorActual': { $exists: true },
      };
    }

    if (filter) {
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        limit: 0,
        sort: '-valores.timestamp',
      };

      // Listado
      this.datos$?.unsubscribe();
      this.datos$ = this.listadosService
        .subscribe<IListado<IReporte>>('reportes', query)
        .subscribe(async (data) => {
          this.reportes = data.datos;
          await this.graficoHistorico();
          console.log(`listado de registros`, data);
        });
      await this.listadosService.getLastValue('reportes', query);
    }
  }

  //

  async ngOnChanges() {
    await Promise.all([this.listar()]);
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
