import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IFilter,
  IListado,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from 'src/app/auxiliares/tabla/filtro/filtro.component';
import { PuntosMedicionService } from '../service';
import { Router } from '@angular/router';
import { CrearEditarPuntosMedicionComponent } from '../crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';

@Component({
  selector: 'app-puntos-scada',
  standalone: false,
  templateUrl: './puntos-scada.component.html',
  styleUrl: './puntos-scada.component.scss',
})
export class PuntosScadaComponent implements OnInit, OnDestroy {
  public puntos?: IPuntoMedicion[] = [];

  // Filtros Tabla
  private centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private localidad: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idLocalidad',
    },
    label: 'Localidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsGrupos',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private agrupacion: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsAgrupaciones',
    },
    label: 'Agrupación',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private scadas: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsScada',
    },
    label: 'SCADAS',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private estado: IFiltroTabla = {
    elementos: [
      {
        label: 'Operativa',
      },
      {
        label: 'Sin Reportar',
      },
      {
        label: 'Resolver',
      },
      {
        label: 'En Mantenimiento',
      },
      {
        label: 'Alerta',
      },
      {
        label: 'Sin Asignar',
      },
    ],
    filter: {
      field: 'estado',
    },
    label: 'Estado',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.estado,
    this.centroOperativo,
    this.localidad,
    this.grupo,
    this.agrupacion,
    this.scadas,
  ];
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public totalCount = 0;

  private filter: IFilter<IPuntoMedicion> = {
    division: 'SCADA',
  };
  private populate: IPopulate[] = [
    {
      path: 'scadas',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
    {
      path: 'localidad2',
      select: 'nombre',
    },
    {
      path: 'grupos',
      select: 'nombre',
    },
    {
      path: 'agrupaciones',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'posicion nombre',
  };
  ////
  private puntos$?: Subscription;

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private service: PuntosMedicionService,
    private router: Router,
  ) {}

  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter = JSON.parse(this.query.filter);
      filter.division = 'SCADA';
      this.query.filter = JSON.stringify(filter);
    } else {
      this.query.filter = JSON.stringify(this.filter);
    }
    // Listado
    this.puntos$?.unsubscribe();
    this.puntos$ = this.listados
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.puntos = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listados.getLastValue('puntosMedicion', this.query);
  }

  public colorChips(nombre: string) {
    return {
      'background-color':
        this.helper.generateColorsFromString(nombre).backgroundColor,
      color: this.helper.generateColorsFromString(nombre).textColor,
    };
  }

  public async paginationChange() {
    await this.listar();
  }

  // ACCIONES
  public async detalles(d: IPuntoMedicion) {
    await this.router.navigate(['main', 'puntosMedicion', `${d._id}`]);
  }

  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async editar(data: IPuntoMedicion) {
    if (!this.helper.puedeEditarPuntosDeMedicion(data)) {
      this.helper.notifError(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async eliminar(dato: IPuntoMedicion): Promise<void> {
    if (!this.helper.puedeEditarPuntosDeMedicion(dato)) {
      this.helper.notifError(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el grupo ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public abrirEnBrowser(punto: IPuntoMedicion) {
    const c = punto.ubicacion;
    if (!c) return;
    const url = `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`;
    window.open(url, '_blank');
  }

  /// HOOKS

  async ngOnInit(): Promise<void> {
    await Promise.all([this.listar()]);
  }

  async ngOnDestroy(): Promise<void> {
    this.puntos$?.unsubscribe();
  }
}
