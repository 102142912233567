import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IPuntoMedicion, IScada } from 'modelos/src';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { GraficoScadaComponent } from '../grafico-scada.component';

@Component({
  selector: 'app-multi-scada-grafico',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, GraficoScadaComponent],
  templateUrl: './multi-scada-grafico.component.html',
  styleUrl: './multi-scada-grafico.component.scss',
})
export class MultiScadaGraficoComponent {
  @Input() punto?: IPuntoMedicion;
  @Input() scadas?: IScada[];
  @Input() range?: { from: string; to: string };
}
