import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IScada } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IconoEstadoComponent } from '../icono-estado/icono-estado.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, IconoEstadoComponent],
  selector: 'app-resumen-scada',
  templateUrl: './resumen-scada.component.html',
  styleUrls: ['./resumen-scada.component.scss'],
})
export class ResumenScadaComponent {
  @Input() scada?: IScada;

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
  ) {}
}
