<mat-tab-group>
  <mat-tab label="Puntos de Medición">
    <app-listado-puntos-medicion></app-listado-puntos-medicion>
  </mat-tab>
  @if (helper.puedeVerScada()) {
    <mat-tab label="SCADAS">
      <app-puntos-scada></app-puntos-scada>
    </mat-tab>
  }
</mat-tab-group>
