import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IAlerta, IReporteScada, IScada } from 'modelos/src';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-ultimo-reporte-scada',
  templateUrl: './ultimo-reporte-scada.component.html',
  styleUrls: ['./ultimo-reporte-scada.component.scss'],
})
export class UltimoReporteScadaComponent implements OnInit {
  @Input() scada?: IScada;

  public ultimoRegistro?: IReporteScada;
  public ultimaAlerta?: IAlerta;

  ngOnInit(): void {
    this.ultimoRegistro = this.scada?.ultimoRegistro?.valores as IReporteScada;
    this.ultimaAlerta = this.scada?.ultimaAlerta;
  }
}
