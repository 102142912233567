import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICreateScada,
  IListado,
  IQueryParam,
  IScada,
  IUpdateScada,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ScadasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IScada>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/scadas`, { params });
  }

  public crear(dato: ICreateScada): Observable<IScada> {
    return this.http.post(`/scadas`, dato);
  }

  public listarPorId(id: string): Observable<IScada> {
    return this.http.get(`/scadas/${id}`);
  }

  public editar(id: string, dato: IUpdateScada): Observable<IScada> {
    return this.http.put(`/scadas/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/scadas/${id}`);
  }

  //

  public getUltimaComunicacion(unidad?: IScada): string {
    const ultimoRegistro = unidad?.ultimoRegistro;
    const ultimaAlerta = unidad?.ultimaAlerta;

    const fechaUltimoRegistro = ultimoRegistro?.fechaCreacion;
    const fechaUltimaAlerta = ultimaAlerta?.fechaCreacion;

    if (fechaUltimoRegistro && fechaUltimaAlerta) {
      return fechaUltimoRegistro > fechaUltimaAlerta
        ? fechaUltimoRegistro
        : fechaUltimaAlerta;
    }

    if (fechaUltimoRegistro) {
      return fechaUltimoRegistro;
    }

    if (fechaUltimaAlerta) {
      return fechaUltimaAlerta;
    }

    return '';
  }
}
