import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Division,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { NavigationService } from '../navigation.service';
import { LoginService } from '../../login/login.service';
import { HelperService } from '../../../auxiliares/helper.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements OnInit, OnDestroy {
  public usuario?: IUsuario;
  public unidadNegocio?: IUnidadNegocio;
  public unidadNegocios: IUnidadNegocio[] = [];
  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public unidadNegocio$?: Subscription;

  public division?: Division;
  public divisiones: Division[] = [];
  public verDivisiones = false;

  constructor(
    public listados: ListadosService,
    public navigation: NavigationService,
    public helper: HelperService,
  ) {}

  public async cambioUnidadNegocio(
    unidadNegocio?: IUnidadNegocio,
  ): Promise<void> {
    this.navigation.setUnidadNegocio(unidadNegocio);
  }

  public async cambioDivision(division?: string): Promise<void> {
    this.navigation.setDivision(division);
  }

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listados
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listados.getLastValue('unidadNegocios', query);
  }

  private getFiltroGlobal() {
    this.unidadNegocio$ = this.navigation
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.unidadNegocio = filtro.unidadNegocio;
      });
    const filtro = this.navigation.getFiltroGlobal();
    this.unidadNegocio = filtro.unidadNegocio;
  }

  private setDivisiones() {
    if (this.helper.puedeVerCorrectoras()) this.divisiones.push('Correctoras');
    if (this.helper.puedeVerUnidadPresion()) this.divisiones.push('Presión');
    if (this.helper.puedeVerResidencial()) this.divisiones.push('Residencial');
    if (this.helper.puedeVerScada()) this.divisiones.push('SCADA');

    this.verDivisiones = this.divisiones.length > 1;
  }

  async ngOnInit(): Promise<void> {
    this.usuario = LoginService.getUsuario();
    this.setDivisiones();
    this.getFiltroGlobal();
    await this.listarUnidadNegocios();
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocio$?.unsubscribe();
  }
}
