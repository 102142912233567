import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  ICorrectora,
  IPuntoMedicion,
  IScada,
  IUnidadPresion,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-icono-estado',
  templateUrl: './icono-estado.component.html',
  styleUrls: ['./icono-estado.component.scss'],
})
export class IconoEstadoComponent implements OnInit, OnChanges {
  @Input() unidadPresion?: IUnidadPresion;
  @Input() correctora?: ICorrectora;
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() scada?: IScada;

  public estado?: string;

  constructor(public helper: HelperService) {}

  async ngOnChanges(): Promise<void> {
    if (this.unidadPresion) {
      this.estado = this.unidadPresion.estadoActual;
    } else if (this.correctora) {
      this.estado = this.correctora.estadoActual;
    } else if (this.puntoMedicion) {
      this.estado =
        this.puntoMedicion.estado ||
        this.puntoMedicion?.correctora?.estadoActual ||
        this.puntoMedicion?.unidadPresion?.estadoActual;
    } else if (this.scada) {
      this.estado = this.scada.estadoActual;
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.unidadPresion) {
      this.estado = this.unidadPresion.estadoActual;
    } else if (this.correctora) {
      this.estado = this.correctora.estadoActual;
    } else if (this.puntoMedicion) {
      this.estado =
        this.puntoMedicion.estado ||
        this.puntoMedicion?.correctora?.estadoActual ||
        this.puntoMedicion?.unidadPresion?.estadoActual;
    } else if (this.scada) {
      this.estado = this.scada.estadoActual;
    }

    console.log('estado scada', this.scada);
  }
}
