import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { RoutingModule } from './routing';
import { GeocodeService } from '../../auxiliares/geocode.service';
import { ScadasComponent } from './scadas/scadas.component';
import { CrearEditarScadaComponent } from './crear-editar-scada/crear-editar-scada.component';
import { DetallesComponent } from './detalles/detalles.component';
import { ResumenScadaComponent } from 'src/app/standalone/resumen-scada/resumen-scada.component';
import { UltimoReporteScadaComponent } from 'src/app/standalone/ultimo-reporte-scada/ultimo-reporte-scada.component';
import { DetallesScadaComponent } from 'src/app/standalone/detalles-scada/detalles-scada.component';
import { GraficoValorActualScadaComponent } from 'src/app/standalone/grafico-valor-actual-scada/grafico-valor-actual-scada.component';
import { GraficoAlertasComponent } from 'src/app/standalone/grafico-alertas/grafico-alertas.component';
import { ListadoReportesScadaComponent } from 'src/app/standalone/listado-reportes-scada/listado-reportes-scada.component';
import { ListadoAlertasComponent } from 'src/app/standalone/listado-alertas/listado-alertas.component';
import { GraficoScadaComponent } from 'src/app/standalone/grafico-scada/grafico-scada.component';

@NgModule({
  declarations: [ScadasComponent, CrearEditarScadaComponent, DetallesComponent],
  imports: [
    CommonModule,
    AuxiliaresModule,
    RoutingModule,
    ResumenScadaComponent,
    UltimoReporteScadaComponent,
    DetallesScadaComponent,
    GraficoValorActualScadaComponent,
    GraficoAlertasComponent,
    ListadoReportesScadaComponent,
    ListadoAlertasComponent,
    GraficoScadaComponent,
  ],
  providers: [GeocodeService],
})
export class ScadasModule {}
