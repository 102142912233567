import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetallesPuntosMedicionComponent } from './detalles-puntos-medicion/detalles-puntos-medicion.component';
import { LandingPuntosComponent } from './landing-puntos/landing-puntos.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPuntosComponent,
  },
  {
    path: ':id',
    component: DetallesPuntosMedicionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PuntosRoutingModule {}
