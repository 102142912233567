<app-resumen-scada [scada]="scada"></app-resumen-scada>

<!-- Ultimo Reporte - SCADA - Presion/Temperatura Actual -->
<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Último Reporte y ultima alerta -->
  <div
    class="fondo-85"
    style="margin: 1em 1em 1em 0; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    @if (scada) {
      <app-ultimo-reporte-scada [scada]="scada"></app-ultimo-reporte-scada>
    }
  </div>

  <!-- SCADA -->
  @if (scada) {
    <div
      class="fondo-85"
      style="margin: 1em; border-bottom-left-radius: 30px"
      fxFlex.gt-sm="32"
    >
      <app-detalles-scada [scadas]="[scada]"></app-detalles-scada>
    </div>
  }

  <!-- Sin scada -->
  <div
    *ngIf="!scada"
    class="fondo-85"
    style="margin: 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <div style="height: 100%; display: flex; flex-direction: column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
          <h1
            style="
              font-size: 18px;
              font-weight: 900;
              padding: 0.5em 0 0.5em 0.5em;
              margin: 0;
            "
          >
            Dispositivo
          </h1>
        </div>
      </div>

      <div
        style="
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <h3>
          <strong> Sin asignar </strong>
        </h3>
      </div>
    </div>
  </div>

  <!-- Grafico valor Actual -->
  <div
    class="fondo-85"
    style="margin: 1em 0 1em 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="32"
  >
    <app-grafico-valor-actual-scada
      [scada]="scada"
    ></app-grafico-valor-actual-scada>
  </div>
</div>

<!-- Grafico Max, Min y Agv / 15 dias y Grafico Alertas -->
<div fxLayout="row" fxLayout.lt-md="column" style="margin: 1em 0">
  <!-- Grafico Max, Min y Agv / 15 dias -->
  <!-- <div
    class="fondo-85"
    style="margin: 1em 1em 1em 0; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="64"
  >
    <app-grafico-min-max-prom
      [unidadPresion]="unidadPresion"
      [range]="range15Dias"
    >
    </app-grafico-min-max-prom>
  </div> -->

  <!-- Grafico Alertas -->
  <div
    class="fondo-85"
    style="margin: 1em 0 1em 1em; border-bottom-left-radius: 30px"
    fxFlex.gt-sm="34"
  >
    <app-grafico-alertas [scada]="scada"></app-grafico-alertas>
  </div>
</div>

<!-- Time Picker y Grafico Max y Min / 1 Año -->
<div
  style="
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 30px;
  "
  class="fondo-85"
>
  <!-- Titulo -->
  <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
    <h1
      style="
        font-size: 18px;
        font-weight: 900;
        padding: 0.5em 0 0.5em 0.5em;
        margin: 0;
      "
    >
      {{ scada?.tipo }} Histórica
    </h1>
  </div>

  <!-- Time Picker -->
  <div style="margin: 1em 0 1em 0">
    <app-datetime-range-picker
      [(range)]="range"
      [presets]="presets"
    ></app-datetime-range-picker>
  </div>

  <!-- Grafico Scada -->
  <div class="fondo-85" style="border-bottom-left-radius: 30px">
    <app-grafico-scada [scada]="scada" [range]="range"></app-grafico-scada>
  </div>
</div>

<!-- Tablas -->
<div style="margin: 1em 0">
  <mat-tab-group>
    <!-- Reportes -->
    @if (scada) {
      <mat-tab label="Reportes">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-reportes-scada
              [scada]="scada"
              [tipo]="scada.tipo"
              [range]="range"
            ></app-listado-reportes-scada>
          </div>
        </ng-template>
      </mat-tab>
    }
    <!-- Alertas -->
    <mat-tab label="Alertas">
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-alertas [scada]="scada"></app-listado-alertas>
        </div>
      </ng-template>
    </mat-tab>
    <!-- Auditorias -->
    <!-- <mat-tab
      label="Auditorias"
      *ngIf="unidadPresion?.dispositivo?.tipoDispositivo === 'VERIBOX MICRO'"
    >
      <ng-template matTabContent>
        <div style="overflow: hidden">
          <app-listado-auditorias-veribox
            [unidadPresion]="unidadPresion"
          ></app-listado-auditorias-veribox>
        </div>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</div>
